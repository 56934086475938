import React from 'react';

function Cancel() {
  return (
    <div className="cancel-page">
      <h2>Payment Canceled</h2>
      <p>Your registration was not completed. If you wish to try again, please go back to the login page and select register.</p>
    </div>
  );
}

export default Cancel;