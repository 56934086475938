import React, { useState, useEffect, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import io from 'socket.io-client';
import Login from './Login';
import './PublicViewingPage.css';
import { Chart, registerables } from 'chart.js';

// Register necessary components
Chart.register(...registerables);

const PublicViewingPage = ({ setHeaderTitle }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [staff, setStaff] = useState(null);
  const [activeMeetings, setActiveMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState('');
  const [meetingTitle, setMeetingTitle] = useState('');
  const [meetingDescription, setMeetingDescription] = useState('');
  const [agendaTitle, setAgendaTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [choices, setChoices] = useState([]);
  const [category, setCategory] = useState('');
  const [results, setResults] = useState(null);
  const [message, setMessage] = useState('Waiting for meeting to commence...');
  const [votingState, setVotingState] = useState('NOT_STARTED'); // NOT_STARTED, COLLECTING, VOTINGCLOSED, COMPLETED
  const [socket, setSocket] = useState(null);

  const [tenantId, setTenantId] = useState(null);
  const [memberId, setMemberId] = useState('');

  const onLoginSuccess = () => {
    setIsLoggedIn(true);
    fetchStaff();
  };

  const fetchStaff = useCallback(async () => {
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/meetings/staff', {
        withCredentials: true, // Ensure cookies are sent with the request
          });
      setStaff(response.data);
      if (response.data && response.data.length > 0) {
        const activeMeetings = response.data.filter(meeting => meeting.is_active);
        setActiveMeetings(activeMeetings);
      }
    } catch (error) {
      console.error('Error fetching staff info:', error);
    }
  },[tenantId, memberId]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchStaff();
    }
  }, [fetchStaff, isLoggedIn, tenantId, memberId]);

  const handleLogout = async() => {
	try {
    const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/logout', {}, {
      withCredentials: true, // Include credentials to ensure cookies are sent
    });
    setIsLoggedIn(false);
    setStaff(null);
    setActiveMeetings([]);
    setSelectedMeeting('');
    setMeetingTitle('');
    setMeetingDescription('');
    setAgendaTitle('');
    setQuestion('');
    setChoices([]);
    setResults(null);
    setMessage('Waiting for meeting to commence...');
    setVotingState('NOT_STARTED');
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
    setHeaderTitle('Meeting Voting System');
	}catch (error) {
    console.error('Error logging out:', error.response || error.message);
	  }
  };
 

  const handleMeetingSelect = async (e) => {
    const meetingId = e.target.value;
    setSelectedMeeting(meetingId);

    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/${meetingId}`, {
        withCredentials: true, // Ensure cookies are sent with the request
          });

      setMeetingTitle(response.data.title);
      setMeetingDescription(response.data.description);
      setHeaderTitle(''); // Update the header title

      const newSocket = io('https://api.mymeetingmanagement.com', {
		withCredentials: true,
        query: { meetingId, tenantId }
      });
      setSocket(newSocket);

      newSocket.on('connect', () => {
        console.log('Connected to WebSocket server');
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server');
      });

      newSocket.on('broadcastUpdate', (data) => {
        console.log('Received message:', data);
        if (data.type === 'NEXT_AGENDA_ITEM') {
          setAgendaTitle(data.title || '');
          setQuestion('');
          setChoices([]);
          setMessage('');
          setVotingState('AGENDA_ONLY');
        } else if (data.type === 'NEXT_VOTING_ITEM') {
          setQuestion(data.question || '');
          setChoices(data.choices || []);
		  setCategory(data.category || '');
          setMessage('');
          setVotingState('COLLECTING');
          if (data.votingOpen === false) {
            setVotingState('VOTINGCLOSED');
          } // Set votingClosed based on votingOpen status
        } else if (data.type === 'SHOW_RESULTS') {
          setResults(data.results || {});
          setMessage('');
          setVotingState('COMPLETED');
        } else if (data.type === 'VOTING_ENDED') {
          setResults(data.results || {});
          setMessage('Voting has ended. Displaying results...');
          setVotingState('COMPLETED');
        } else if (data.type === 'VOTING_CLOSED') {
          setVotingState('VOTINGCLOSED');
        }
      });

      return () => {
        newSocket.close();
      };
    } catch (error) {
      console.error('Error fetching meeting info:', error);
    }
  };
  
 
  //code for seperating colours in the chart but not yet working due to how it reads the choice text
  const renderResults = () => {
  if (results && Object.keys(results).length > 0) {
    const data = {
      labels: [],
      datasets: [{
        label: 'Votes',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      }],
    };

    if (choices.length > 0) {
      data.labels = choices.map(choice => choice.choice_text);
      data.datasets[0].data = choices.map(choice => results[choice.id] || 0);
      
      if (category === 'Question' || category === 'Motion') {
        data.datasets[0].backgroundColor = choices.map(choice => {
          if (choice.choice_text.toLowerCase() === 'yes') return 'rgba(75, 192, 75, 0.2)';
          if (choice.choice_text.toLowerCase() === 'no') return 'rgba(192, 75, 75, 0.2)';
          if (choice.choice_text.toLowerCase() === 'abstain') return 'rgba(75, 75, 192, 0.2)';
          return 'rgba(75, 192, 192, 0.2)'; // Default color if none match
        });
      } else {
        data.datasets[0].backgroundColor = choices.map(() => 'rgba(75, 192, 192, 0.2)');
        data.datasets[0].borderColor = choices.map(() => 'rgba(75, 192, 192, 1)');
      }
    } else {
      data.labels = Object.keys(results);
      data.datasets[0].data = Object.values(results);
      data.datasets[0].backgroundColor = Object.keys(results).map(() => 'rgba(75, 192, 192, 0.2)');
      data.datasets[0].borderColor = Object.keys(results).map(() => 'rgba(75, 192, 192, 1)');
    }

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const resultMessage = generateResultMessage(results);

    return (
      <div>
        <Bar data={data} options={options} />
        <p>{resultMessage}</p>
      </div>
    );
  }

  if (!results || Object.keys(results).length === 0) return 'No votes were cast for this item.';
  return <p>Collecting results...</p>;
};

  
  const generateResultMessage = (results) => {
  
  const yesVotes = results['yes'] || 0;
  const noVotes = results['no'] || 0;
  const abstainVotes = results['abstain'] || 0;
  const totalVotes = yesVotes + noVotes;

  if (category === 'Question' || category === 'Motion') {
    if (totalVotes === 0) {
      return 'No votes have been cast for this item.';
    }
    if (yesVotes > noVotes) {
      return `The YES's win with ${yesVotes} of ${totalVotes} votes, ${abstainVotes} members abstained.`;
    } else if (noVotes > yesVotes) {
      return `The NO's win with ${noVotes} of ${totalVotes} votes, ${abstainVotes} members abstained.`;
    } else {
      return `The vote is tied at ${yesVotes} each and ${abstainVotes} members abstained, therefore undecided.`;
    }
  } else if (question.toLowerCase().includes('candidate')) {
    const maxVotes = Math.max(...Object.values(results));
    const maxVoteChoices = Object.keys(results).filter(key => results[key] === maxVotes);
    if (maxVoteChoices.length > 1) {
      return 'The vote is tied and therefore undecided.';
    }
    const winningChoice = choices.find(choice => choice.id.toString() === maxVoteChoices[0])?.choice_text;
    const totalChoiceVotes = Object.values(results).reduce((sum, count) => sum + count, 0);
    return `The Membership has selected ${winningChoice} with the majority vote of ${maxVotes} votes out of ${totalChoiceVotes}.`;
  } else {
    const maxVotes = Math.max(...Object.values(results));
    const maxVoteChoices = Object.keys(results).filter(key => results[key] === maxVotes);
    if (maxVoteChoices.length > 1) {
      return 'The vote is tied and therefore undecided.';
    }
    const winningChoice = choices.find(choice => choice.id.toString() === maxVoteChoices[0])?.choice_text;
    const totalChoiceVotes = Object.values(results).reduce((sum, count) => sum + count, 0);
    return `${winningChoice} wins the vote with ${maxVotes} out of ${totalChoiceVotes} votes.`;
  }
};

  if (!isLoggedIn) {
    return <Login onLoginSuccess={onLoginSuccess} />;
  }

  if (!staff) {
    return (
      <div className="error-page">
        <p>You are not nominated as Staff for any active meeting, See you meeting organiser</p>
        <button className="retry-button" onClick={handleLogout}>Retry Login</button>
      </div>
    );
  }

  if (staff.length === 0) {
    return <p>You are not registered as Staff to any Meetings.</p>;
  }

  return (
    <div className="public-viewing-page">
      <h1>{selectedMeeting ? meetingTitle : 'Select a Meeting'}</h1>
      <h2>{meetingDescription}</h2>
      <div className="spacer"></div>
      {!selectedMeeting && (
        <div className="meeting-select-container">
          <select className="select-meeting" value={selectedMeeting} onChange={handleMeetingSelect}>
            <option value="">Select an Active Meeting</option>
            {activeMeetings.map(meeting => (
              <option key={meeting.id} value={meeting.id}>{meeting.title}</option>
            ))}
          </select>
        </div>
      )}
      {selectedMeeting && votingState === 'NOT_STARTED' && <p>{message}</p>}
      {selectedMeeting && votingState === 'AGENDA_ONLY' && (
        <>
          <h3>{agendaTitle}</h3>
        </>
      )}
      {selectedMeeting && votingState === 'COLLECTING' && (
        <>
          <h3>{agendaTitle}</h3>
          <div className="vertical-content">
            <div className="voting-item">
              <h4>{question}</h4>
              {choices.map(choice => <p key={choice.id}>{choice.choice_text}</p>)}
            </div>
            <div className="results">
              <p>Collecting results...</p>
            </div>
          </div>
        </>
      )}
      {selectedMeeting && votingState === 'VOTINGCLOSED' && (
        <>
          <h3>{agendaTitle}</h3>
          <div className="vertical-content">
            <div className="voting-item">
              <h4>{question}</h4>
              {choices.map(choice => <p key={choice.id}>{choice.choice_text}</p>)}
            </div>
            <div className="results">
              <p>Voting has closed...</p>
            </div>
          </div>
        </>
      )}
      {selectedMeeting && votingState === 'COMPLETED' && (
        <>
          <h3>{agendaTitle}</h3>
          <div className="vertical-content">
            <div className="voting-item">
              <h4>{question}</h4>
              {choices.map(choice => <p key={choice.id}>{choice.choice_text}</p>)}
            </div>
            <div className="results">
              {renderResults()}
            </div>
          </div>
        </>
      )}
      <div className="logout-button-container">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default PublicViewingPage;
