
//WORKING CODE BEFORE ADDING ATTENDEE COUNT
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './Login';
import './MemberActivation.css'; // Import the new CSS file

const MemberActivation = () => {
  const [activeMeetings, setActiveMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState('');
  const [memberIdQuery, setMemberIdQuery] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [activationMessage, setActivationMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isStaff, setIsStaff] = useState(false);

  const [memberId, setMemberId] = useState(null);
  const [tenantId, setTenantId] = useState(null);;

  const onLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = async() => {
	try {
    const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/logout', {}, {
      withCredentials: true, // Include credentials to ensure cookies are sent
    });
    setIsLoggedIn(false);
    setActiveMeetings([]);
    setSelectedMeeting('');
    setMemberIdQuery('');
    setNameQuery('');
    setSearchResults([]);
    setActivationMessage('');
	}catch (error) {
    console.error('Error logging out:', error.response || error.message);
	  }
  };
  

  useEffect(() => {
    if (isLoggedIn) {
      const fetchStaff = async () => {
        try {
          console.log('Fetching staff data');
          const response = await axios.get('https://api.mymeetingmanagement.com/api/meetings/staff', {
            withCredentials: true, // Ensure cookies are sent with the request
			params: {
			member_id: memberId, // Ensure memberId is being passed
			},
          });
          if (response.data && response.data.length > 0) {
            const activeMeetings = response.data.filter(meeting => meeting.is_active);
            setActiveMeetings(activeMeetings);
            setIsStaff(true); // User is staff for at least one meeting
          } else {
            setIsStaff(false); // User is not staff for any active meetings
          }
        } catch (error) {
          console.error('Error fetching staff info:', error);
          setIsStaff(false);
        }
      };
      fetchStaff();
    }
  }, [isLoggedIn, tenantId, memberId]);

  const handleSearch = async () => {
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/members/search', {
        withCredentials: true, // Ensure cookies are sent with the request
        params: {
          member_id: memberIdQuery,
          name: nameQuery
        },
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching members:', error);
    }
  };

  const handleActivate = async (memberId, name) => {
    const requestBody = {
        meeting_id: selectedMeeting,
        member_id: memberId,  // Ensure this is the member_id from users table
        name: name
    };

    try {
        const response = await axios.post('https://api.mymeetingmanagement.com/api/members/addAttendee', requestBody, {
            withCredentials: true, // Ensure cookies are sent with the request
        });
        console.log('Activation response:', response.data);
        setActivationMessage('Member activated successfully!');
        // Clear search fields and results
        setMemberIdQuery('');
        setNameQuery('');
        setSearchResults([]);
    } catch (error) {
        console.error('Error activating member:', error);
        if (error.response && error.response.data && error.response.data.error === 'Member is already in Attendance') {
            setActivationMessage('Member is already in Attendance');
        } else {
            setActivationMessage('Failed to activate member.');
        }
    }
};

  if (!isLoggedIn) {
    return <Login onLoginSuccess={onLoginSuccess} />;
  }

  if (!isStaff) {
    return (
      <div className="error-page">
        <p>You are not nominated as Staff for any active meeting, See you meeting organiser</p>
        <button className="retry-button" onClick={handleLogout}>Retry Login</button>
      </div>
    );
  }

  return (
    <div className="member-activation-container">
      <h1 className="member-activation-header">Member Activation Portal</h1>
      <div className="logout-button-container">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      {activeMeetings.length === 0 ? (
        <p>No Active Meetings Running</p>
      ) : (
        <>
          <select className="select-meeting" value={selectedMeeting} onChange={(e) => setSelectedMeeting(e.target.value)}>
            <option value="">Select an Active Meeting</option>
            {activeMeetings.map(meeting => (
              <option key={meeting.id} value={meeting.id}>{meeting.title}</option>
            ))}
          </select>
          {selectedMeeting && (
            <>
              <div className="search-section">
                <input 
                  type="text" 
                  placeholder="Search by Member ID" 
                  value={memberIdQuery}
                  onChange={(e) => setMemberIdQuery(e.target.value)}
                />
                <input 
                  type="text" 
                  placeholder="Search by Name" 
                  value={nameQuery}
                  onChange={(e) => setNameQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
              </div>
              <ul className="search-results">
                {searchResults.map(member => (
                  <li key={member.member_id}>
                    {member.name} - {member.member_id}
                    <button onClick={() => handleActivate(member.member_id, member.name)}>Activate</button>
                  </li>
                ))}
              </ul>
              {activationMessage && <p className="activation-message">{activationMessage}</p>}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MemberActivation;






