/* import React from 'react';

const WelcomeMessage = () => {
  return <h1>Welcome to the Meeting Voting System</h1>;
};

export default WelcomeMessage;

 */
 
import React from 'react';

const WelcomeMessage = () => {
  return (
    <div>
      <h1 style={{ marginBottom: '40px' }}>Welcome to the Meeting Management System</h1>  {/* Adjust the margin as needed */}
      <p>
        URL for members to send to members for individual login and voting:
        <br />
        <strong style={{ color: 'navy' }}>
          <a href="https://www.mymeetingmanagement.com/vote" target="_blank" rel="noopener noreferrer">https://www.mymeetingmanagement.com/vote</a>
        </strong>
      </p>
      <p>
        Staff access for member attendance activation at the door.
        <br />
        <strong style={{ color: 'navy' }}>
          <a href="https://www.mymeetingmanagement.com/activate-member" target="_blank" rel="noopener noreferrer">https://www.mymeetingmanagement.com/activate-member</a>
        </strong>
      </p>
      <p>
        Staff access to Public Viewing Page for projecting results during meeting.
        <br />
        <strong style={{ color: 'navy' }}>
          <a href="https://www.mymeetingmanagement.com/public-view" target="_blank" rel="noopener noreferrer">https://www.mymeetingmanagement.com/public-view</a>
        </strong>
      </p>
      <p>
        Staff access to the public voting booth page during meeting.
        <br />
        <strong style={{ color: 'navy' }}>
          <a href="https://www.mymeetingmanagement.com/voting-Booth" target="_blank" rel="noopener noreferrer">https://www.mymeetingmanagement.com/voting-Booth</a>
        </strong>
      </p>
    </div>
  );
};

export default WelcomeMessage;