import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './RunMeetingPage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomReactQuill from './CustomReactQuill';
import { useDropzone } from 'react-dropzone';

const RunMeetingPage = () => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [openTimestamp, setOpenTimestamp] = useState('');
  const [closeTimestamp, setCloseTimestamp] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [agendaItems, setAgendaItems] = useState([]);
  const [itemIndex, setItemIndex] = useState(-1); // Start with no current item
  const [currentAgendaItem, setCurrentAgendaItem] = useState(null);
  const [currentVotingItem, setCurrentVotingItem] = useState(null);
  const [nextAgendaItem, setNextAgendaItem] = useState(null);
  const [nextVotingItem, setNextVotingItem] = useState(null);
  const [newVotingItem, setNewVotingItem] = useState({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
  const [staffSearch, setStaffSearch] = useState('');
  const [votingClosed, setVotingClosed] = useState(false); // New state variable
  const [attendeesCount, setAttendeesCount] = useState(0); // State for attendee count
  const [tenantId, setTenantId] = useState('');
  const [socket, setSocket] = useState(null);
  const [loggedInRole, setLoggedInRole] = useState(null);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState(null);
  const [choiceText, setChoiceText] = useState('');
  const [selectedChoices, setSelectedChoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoleAndMeetings = async () => {
      try {
        const roleResponse = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
          withCredentials: true, // Ensure cookies are sent with the request
        });

        const { role, tenantId } = roleResponse.data;
        console.log('Check Data Response: ', roleResponse.data);
        setLoggedInRole(role);
        setTenantId(tenantId);

        const meetingsResponse = await axios.get('https://api.mymeetingmanagement.com/api/meetings', {
          withCredentials: true, // Ensure cookies are sent with the request
        });

        setMeetings(meetingsResponse.data);
      } catch (error) {
        console.error('Error during role or meetings fetch:', error);
        navigate('/login'); // Redirect to login on error
      }
    };

    fetchRoleAndMeetings();
  }, [tenantId, navigate]);

  const getAttendeesList = async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/attendance/${selectedMeeting}`, {
        withCredentials: true,
      });
      setAttendeesCount(response.data); // Update attendee count state
    } catch (error) {
      console.error('Error fetching attendees list:', error);
    }
  };

  const fetchCompleteVotingItem = async (votingItemId) => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/voting-items/item/${votingItemId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching complete voting item:', error);
      return null;
    }
  };

  const handleMeetingSelect = async (e) => {
    const meetingId = e.target.value;
    setSelectedMeeting(meetingId);
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/${meetingId}/full-agenda`, {
        withCredentials: true,
      });

      const agendaWithVoting = [];
      const agendaItemsMap = new Map();

      response.data.agendaItems.forEach(agenda => {
        agendaItemsMap.set(agenda.id, { ...agenda, votingItems: [] });
      });

      response.data.votingItems.forEach(votingItem => {
        if (agendaItemsMap.has(votingItem.agenda_id)) {
          agendaItemsMap.get(votingItem.agenda_id).votingItems.push(votingItem);
        }
      });

      const sortedAgendaItems = Array.from(agendaItemsMap.values()).sort((a, b) => a.order - b.order);

      sortedAgendaItems.forEach(agendaItem => {
        agendaWithVoting.push(agendaItem);
        agendaItem.votingItems.sort((a, b) => a.order - b.order).forEach(votingItem => {
          agendaWithVoting.push(votingItem);
        });
      });

      setAgendaItems(agendaWithVoting);
      setItemIndex(-1); // Start with no current item
      setCurrentAgendaItem(null);
      setCurrentVotingItem(null);

      setNextItem(agendaWithVoting, 0);
    } catch (error) {
      console.error('Error fetching full agenda:', error);
    }
  };

  const handleNext = async () => {
    const nextIndex = itemIndex + 1;
    if (nextIndex < agendaItems.length) {
      setItemIndex(nextIndex);
      const nextItem = agendaItems[nextIndex];

      if (nextItem.category) { // It's a voting item
        setCurrentVotingItem(nextItem);
        const room = `${tenantId}-${nextItem.meeting_id}`;
        if (!meetings[room]) {
          meetings[room] = { clients: [], currentAgendaItem: null, currentVotingItem: null, votingOpen: true };
        }
        meetings[room].votingOpen = true;
        setVotingClosed(false);

        const completeVotingItem = await fetchCompleteVotingItem(nextItem.id);
        if (completeVotingItem) {
          socket.emit('broadcastUpdate', {
            type: 'NEXT_VOTING_ITEM',
            nextVotingItem: completeVotingItem,
            votingOpen: true
          });
        } else {
          console.error('Failed to fetch complete voting item.');
        }
      } else { // It's an agenda item
        setCurrentAgendaItem(nextItem);
        setCurrentVotingItem(null);
        setVotingClosed(false);
        socket.emit('broadcastUpdate', {
          type: 'NEXT_AGENDA_ITEM',
          nextAgendaItem: nextItem
        });
      }

      setNextItem(agendaItems, nextIndex + 1);
    } else {
      alert('No more items.');
    }
  };

  const setNextItem = async (agendaWithVoting, index) => {
    if (index < agendaWithVoting.length) {
      const nextItem = agendaWithVoting[index];
      if (nextItem.category) {
        if (nextItem.agenda_id === currentAgendaItem?.id) {
          setNextAgendaItem(currentAgendaItem);
        } else {
          const newNextAgendaItem = agendaWithVoting.find(item => item.id === nextItem.agenda_id);
          setNextAgendaItem(newNextAgendaItem);
        }

        const completeVotingItem = await fetchCompleteVotingItem(nextItem.id);
        setNextVotingItem(completeVotingItem);
      } else {
        setNextAgendaItem(nextItem);
        setNextVotingItem(null);
      }
    } else {
      setNextAgendaItem(null);
      setNextVotingItem(null);
    }
  };

  const toggleMeetingStartStop = async () => {	  
    if (meetingStarted) {
      try {
        const response = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}`, {
          withCredentials: true,
        });

        if (response.data.is_open === 1) {
          await axios.put(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/end-time`, {}, {
            withCredentials: true,
          });
        }

        await axios.put(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/stop`, {}, {
          withCredentials: true,
        });

        setMeetingStarted(false);
        if (socket) {
          socket.close();
          setSocket(null);
        }
      } catch (error) {
        console.error('Error stopping the meeting:', error);
      }
    } else {
      try {
        await axios.put(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/start`, {}, {
          withCredentials: true,
        });

        const newSocket = io('https://api.mymeetingmanagement.com', {
			withCredentials: true,
          query: { meetingId: selectedMeeting, tenantId }
        });
        setSocket(newSocket);

        newSocket.on('connect', () => {
          console.log('Connected to WebSocket server');
        });

        newSocket.on('disconnect', () => {
          console.log('Disconnected from WebSocket server');
        });

        newSocket.on('broadcastUpdate', (data) => {
          if (data.type === 'VOTING_CLOSED') {
            setVotingClosed(true);
          }
        });

        setMeetingStarted(true);
      } catch (error) {
        console.error('Error starting the meeting:', error);
      }
    }
  };

  const handleStaffSearchChange = (e) => {
    setStaffSearch(e.target.value);
  };

  const handleStaffSelect = async () => {
    if (!staffSearch) {
      alert('Please enter a Member ID');
      return;
    }

    try {
      const response = await axios.post(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/staff`, {
        memberId: staffSearch,
      }, {
        withCredentials: true,
      });

      if (response.status === 200) {
        alert('Staff added successfully');
        fetchStaffList();
      } else {
        alert('Failed to add staff');
      }
    } catch (error) {
      console.error('Error adding staff:', error);
      alert('Error adding staff');
    }

    setStaffSearch(''); // Clear the search input
  };

  const fetchStaffList = useCallback(async () => {
    if (!selectedMeeting) return;
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/staff`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        setStaffList(response.data);
      } else {
        console.error('Failed to fetch staff list');
      }
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  }, [tenantId, selectedMeeting]);

  useEffect(() => {
    if (selectedMeeting) {
      fetchStaffList();
    }
  }, [selectedMeeting, fetchStaffList]);

  const handleStaffRemove = async (memberId) => {
    if (!selectedMeeting) {
      alert('Please select a meeting first.');
      return;
    }

    try {
      const response = await axios.delete(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/staff/${memberId}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        fetchStaffList();
        alert('Staff removed successfully');
      } else {
        alert('Failed to remove staff');
      }
    } catch (error) {
      console.error('Error removing staff:', error);
      alert('Error removing staff');
    }
  };

  const openMeeting = async () => {
    const timestamp = new Date().toLocaleString();
    const timestampToDb = new Date();
    const formattedTimestamp = formatDateForMySQL(timestampToDb);
    setOpenTimestamp(timestamp);
    if (!selectedMeeting) {
      alert('Please select a meeting first.');
      return;
    }

    try {
      const response = await axios.put(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/start-time`, {
        start_time: formattedTimestamp
      }, {
        withCredentials: true,
      });

      if (response.status === 200) {
      } else {
        alert('Failed to update start time');
      }
    } catch (error) {
      console.error('Error updating start time:', error);
      alert('Error updating start time');
    }
  };

  const closeMeeting = async () => {
    const timestamp = new Date().toLocaleString();
    const timestampToDb = new Date();
    const formattedTimestamp = formatDateForMySQL(timestampToDb);
    setCloseTimestamp(timestamp);
    if (!selectedMeeting) {
      alert('Please select a meeting first.');
      return;
    }

    try {
      const response = await axios.put(`https://api.mymeetingmanagement.com/api/meetings/${selectedMeeting}/end-time`, {
        end_time: formattedTimestamp
      }, {
        withCredentials: true,
      });

      if (response.status === 200) {
      } else {
        alert('Failed to update end time');
      }
    } catch (error) {
      console.error('Error updating end time:', error);
      alert('Error updating end time');
    }
  };

  const handleChoiceChange = async (index, field, value) => {
    const newChoices = [...newVotingItem.choices];
    if (field === 'profile_picture') {
      const reader = new FileReader();
      reader.onloadend = () => {
        newChoices[index][field] = reader.result;
        setNewVotingItem({ ...newVotingItem, choices: newChoices });
      };
      reader.readAsDataURL(value);
    } else {
      newChoices[index][field] = value;
      setNewVotingItem({ ...newVotingItem, choices: newChoices });
    }
  };

  const handleAddChoice = () => {
    setNewVotingItem({ ...newVotingItem, choices: [...newVotingItem.choices, { choice_text: '', profile_picture: '', bio: '' }] });
  };

  const handleSaveBio = async (index, bio) => {
    const choice = newVotingItem.choices[index];
    const updatedChoices = newVotingItem.choices.map((c, i) =>
      i === index ? { ...c, bio } : c
    );
    setNewVotingItem({ ...newVotingItem, choices: updatedChoices });
    setEditingChoiceIndex(null);

    try {
      await axios.put(`https://api.mymeetingmanagement.com/api/choices/${choice.id}`, {
        choice_text: choice.choice_text,
        bio: bio,
        profile_picture: choice.profile_picture,
      }, {
        withCredentials: true,
      });
    } catch (error) {
      console.error('Error saving bio:', error);
    }
  };

  
  const handleFileUpload = async (acceptedFiles, index) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = async () => {
        const updatedChoices = newVotingItem.choices.map((c, i) =>
            i === index ? { ...c, profile_picture: reader.result } : c
        );

        setNewVotingItem({ ...newVotingItem, choices: updatedChoices });

        const choice = updatedChoices[index];
        console.log('Uploading profile picture for choice:', choice);

        // Check if the choice has an id
        if (choice && choice.id) {
            console.log('Updating choice with ID:', choice.id);
            try {
                await axios.put(`https://api.mymeetingmanagement.com/api/choices/${choice.id}`, {
                    choice_text: choice.choice_text,
                    bio: choice.bio,
                    profile_picture: reader.result,
                }, {
                    withCredentials: true,
                });
            } catch (error) {
                console.error('Error uploading profile picture:', error);
            }
        } else {
            // If choice.id is missing, log an informative message
            console.error('Choice ID is undefined or invalid. The profile picture will be uploaded after the choice is saved.');
        }
    };

    reader.readAsDataURL(file);
};
const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0 && editingChoiceIndex !== null) {
        handleFileUpload(acceptedFiles, editingChoiceIndex);
      }
    }
  });


  const handleSubmit = async (e) => {
  e.preventDefault();

  // Ensure currentAgendaItem is not null before proceeding
  if (!currentAgendaItem || !currentAgendaItem.id) {
    alert('Please select an agenda item before adding a voting item.');
    return;
  }

  try {
    const maxOrder = agendaItems.reduce((max, item) => (item.order > max ? item.order : max), 0);
    const newOrder = maxOrder + 1;

    const response = await axios.post(`https://api.mymeetingmanagement.com/api/voting-items/${currentAgendaItem.id}/insert`, {
      ...newVotingItem,
      order: newOrder,
      choices: newVotingItem.category === 'MultipleChoice' || newVotingItem.category === 'CandidateVote'
        ? newVotingItem.choices
        : []
    }, {
      withCredentials: true,
    });

    const newItem = response.data;
    const updatedAgendaItems = [...agendaItems];
    updatedAgendaItems.splice(itemIndex + 1, 0, newItem);

    for (let i = 0; i < updatedAgendaItems.length; i++) {
      updatedAgendaItems[i].order = i;
    }

    setAgendaItems(updatedAgendaItems);
    setNewVotingItem({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });

    setNextItem(updatedAgendaItems, itemIndex + 1);
  } catch (error) {
    console.error('Error adding voting item:', error);
  }
};


  const formatDateForMySQL = (date) => {
    const padToTwoDigits = (num) => num.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = padToTwoDigits(date.getMonth() + 1);
    const day = padToTwoDigits(date.getDate());
    const hours = padToTwoDigits(date.getHours());
    const minutes = padToTwoDigits(date.getMinutes());
    const seconds = padToTwoDigits(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleCloseVoting = async () => {
    socket.emit('broadcastUpdate', { type: 'CLOSE_VOTING' });
    setVotingClosed(true);
  };

  const handleShowResults = async () => {
    const response = await axios.get(`https://api.mymeetingmanagement.com/api/voting/results/${currentVotingItem.id}`, {
      withCredentials: true,
    });
    socket.emit('broadcastUpdate', { type: 'VOTE_RESULTS', results: response.data });
  };

  if (loggedInRole !== 'organizer') {
    return <label htmlFor="title">You do not have permission to perform this action</label>
  }

  return (
    <div className="run-meeting-page">
      <h1>Run Meeting</h1>
      <div className="form-group">
        <label htmlFor="meeting-select">Select Meeting</label>
        <div className="input-group">
          <select id="meeting-select" className="form-control" onChange={handleMeetingSelect} value={selectedMeeting || ''}>
            <option value="" disabled>Select Meeting</option>
            {meetings.map(meeting => (
              <option key={meeting.id} value={meeting.id}>{meeting.title}</option>
            ))}
          </select>
          <div className="input-group-append">
            <button className={`btn ${meetingStarted ? 'btn-danger' : 'btn-success'}`} onClick={toggleMeetingStartStop}>
              {meetingStarted ? 'Stop Meeting' : 'Start Meeting'}
            </button>
          </div>
        </div>
      </div>

      {meetingStarted && (
        <>
          <div className="form-group">
            <label htmlFor="staff-search">Add Staff by Member ID</label>
            <div className="input-group">
              <input
                id="staff-search"
                type="text"
                className="form-control"
                placeholder="Enter Member ID"
                value={staffSearch}
                onChange={handleStaffSearchChange}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" onClick={handleStaffSelect}>Add Staff</button>
              </div>
            </div>
          </div>

          <ul className="list-group mb-4">
            {staffList.map(staff => (
              <li key={`${staff.member_id}-${staff.name}`} className="list-group-item d-flex justify-content-between align-items-center">
                {staff.member_id} - {staff.name}
                <button className="btn btn-danger btn-sm" onClick={() => handleStaffRemove(staff.member_id)}>Remove</button>
              </li>
            ))}
          </ul>

          <div className="form-group d-flex align-items-center">
            <button className="btn btn-secondary" onClick={openMeeting}>Open Meeting</button>
            {openTimestamp && <span className="ml-2">{openTimestamp}</span>}
          </div>
          <div className="form-group d-flex align-items-center">
            <button className="btn btn-secondary" onClick={closeMeeting}>Close Meeting</button>
            {closeTimestamp && <span className="ml-2">{closeTimestamp}</span>}
          </div>

          <div className="agenda-section">
            <h2>Current Agenda Item: {currentAgendaItem ? currentAgendaItem.title : 'None'}</h2>
            <h2>Current Voting Item: {currentVotingItem ? currentVotingItem.question : 'None'}</h2>
            <div style={{ marginBottom: '20px' }}></div>
            <h2>Next Agenda Item: {nextAgendaItem ? nextAgendaItem.title : 'None'}</h2>
            <h2>Next Voting Item: {nextVotingItem ? nextVotingItem.question : 'None'}</h2>
            <button onClick={handleCloseVoting} className="btn btn-danger ml-2" style={{ marginRight: '20px' }}>Close Voting</button>
            <button onClick={handleNext} className="btn btn-primary">Next</button>
            {votingClosed && (
              <button onClick={handleShowResults} className="btn btn-info" style={{ marginLeft: '10px' }}>Show Results</button>
            )}
            <div className="form-group d-flex flex-column align-items-center justify-content-center">
              <button className="btn btn-info" onClick={getAttendeesList}>Update Attendance</button>
              <span className="mt-2">{attendeesCount} members in attendance</span>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <h3>Add New Voting Item</h3>
            <div className="form-group">
              <label>Category</label>
              <select name="category" className="form-control" value={newVotingItem.category} onChange={(e) => setNewVotingItem({ ...newVotingItem, category: e.target.value })}>
                <option value="Question">Question</option>
                <option value="Motion">Motion</option>
                <option value="MultipleChoice">Multiple Choice</option>
                <option value="CandidateVote">Candidate Vote</option>
              </select>
            </div>
            <div className="form-group">
              <label>Question/Motion</label>
              <input type="text" name="question" className="form-control" value={newVotingItem.question} onChange={(e) => setNewVotingItem({ ...newVotingItem, question: e.target.value })} />
            </div>
            {newVotingItem.category === 'Motion' && (
              <>
                <div className="form-group">
                  <label>Raised By</label>
                  <input type="text" name="raised_by" className="form-control" value={newVotingItem.raised_by} onChange={(e) => setNewVotingItem({ ...newVotingItem, raised_by: e.target.value })} />
                </div>
                <div className="form-group">
                  <label>Seconded By</label>
                  <input type="text" name="seconded_by" className="form-control" value={newVotingItem.seconded_by} onChange={(e) => setNewVotingItem({ ...newVotingItem, seconded_by: e.target.value })} />
                </div>
              </>
            )}
            {(newVotingItem.category === 'MultipleChoice' || newVotingItem.category === 'CandidateVote') && (
              <>
                <div className="form-group">
                  <label>Choices</label>
                  {newVotingItem.choices.map((choice, index) => (
  <div key={index} className="choice-container">
    <input
      type="text"
      className="form-control mb-2"
      value={choice.choice_text}
      onChange={(e) => handleChoiceChange(index, 'choice_text', e.target.value)}
      placeholder={`Choice ${index + 1}`}
    />
    {newVotingItem.category === 'CandidateVote' && (
      <div className="choice-text-and-button">
        <button 
          type="button" 
          className="btn btn-secondary"
          onClick={() => setEditingChoiceIndex(editingChoiceIndex === index ? null : index)}
        >
          Edit Bio
        </button>
        {editingChoiceIndex === index && (
          <div className="bio-subform mt-2">
            <CustomReactQuill
              value={choice.bio}
              onChange={(value) => handleChoiceChange(index, 'bio', value)}
              placeholder="Enter candidate bio"
            />
            <div {...getRootProps()} className="btn btn-secondary mt-2">
              <input {...getInputProps()} />
              Upload Profile Picture
            </div>
          </div>
        )}
      </div>
    )}
  </div>
))}



                  <button type="button" className="btn btn-secondary mt-2" onClick={handleAddChoice}>Add Choice</button>
                </div>
              </>
            )}
            <button type="submit" className="btn btn-primary">Add Voting Item</button>
          </form>

          <div style={{ marginTop: '20px', fontSize: '10pt' }}>
            <p></p>
          </div>
        </>
      )}
    </div>
  );
};

export default RunMeetingPage;



