//WORKING CODE 
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import AgendaManagement from './AgendaManagement';

function MeetingManagement() {
  const [meetings, setMeetings] = useState([]);
  const [meeting, setMeeting] = useState({ title: '', description: '', date: '' });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);
  const [selectedAgendaId, setSelectedAgendaId] = useState(null);

  //const [tenantId, setTenantId] = useState(null);
  const [loggedInRole, setLoggedInRole] = useState(null);
  const [role, setRole] = useState(null);

	const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  setLoggedInRole(role); // Set the role in your state
		  setRole(role);
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};

  const fetchMeetings = useCallback(async () => { //async () => {
	  checkLoginStatus();
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/meetings', {
         withCredentials: true, // Include cookies in requests
      });
      setMeetings(response.data);
    } catch (error) {
      console.error('Error fetching meetings:', error);
    }
  },[]);
  
  useEffect(() => {
    fetchMeetings();
  }, [fetchMeetings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMeeting({ ...meeting, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axios.put(`https://api.mymeetingmanagement.com/api/meetings/${editId}`, meeting, {
           withCredentials: true, // Include cookies in requests
        });
        setEditMode(false);
        setEditId(null);
      } else {
        await axios.post('https://api.mymeetingmanagement.com/api/meetings', meeting, {
           withCredentials: true, // Include cookies in requests
        });
      }
      setMeeting({ title: '', description: '', date: '' });
      fetchMeetings();
    } catch (error) {
      console.error('Error saving meeting:', error);
    }
  };
    
      

  const handleEdit = (meeting) => {
	  if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setMeeting(meeting);
    setEditMode(true);
    setEditId(meeting.id);
  };

  const handleDelete = async (id) => {
	  if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    try {
      await axios.delete(`https://api.mymeetingmanagement.com/api/meetings/${id}`, {
         withCredentials: true, // Include cookies in requests
      });
      fetchMeetings();
    } catch (error) {
      console.error('Error deleting meeting:', error);
    }
  };

  const handleMeetingSelect = (meetingId) => {
    setSelectedMeetingId(meetingId);
    setSelectedAgendaId(null); // Clear the selected agenda when a new meeting is selected
  };

  const handleAgendaSelect = (agendaId) => {
    setSelectedAgendaId(agendaId);
  };
  
  const handleGenerateMinutes = async (meetingId) => {
  if (!meetingId) {
    return alert('Please select a meeting first.');
  }

  try {
    const response = await axios.get(`https://api.mymeetingmanagement.com/api/documents/${meetingId}/minutes`, {
       withCredentials: true, // Include cookies in requests
      responseType: 'blob', // Important
    });

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'meeting-minutes.docx'); // or any other extension
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error generating minutes:', error);
  }
};

const handleGenerateAgenda = async (meetingId) => {
  try {
    const response = await axios.get(`https://api.mymeetingmanagement.com/api/documents/${meetingId}/agenda`, {
       withCredentials: true, // Include cookies in requests
       responseType: 'blob', // Important to handle binary data
    });

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Meeting-agenda.docx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error generating agenda document:', error);
  }
};

if (loggedInRole !== 'organizer') {
        return <label htmlFor="title">You do not have permission to perform this action</label>
      }

  return (
    <div className="main-content">
      <div className="column column-left">
        <h2>Meetings</h2>
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="form-group">
            <label htmlFor="title">Meeting Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={meeting.title}
              onChange={handleChange}
              placeholder="Meeting Title"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              className="form-control"
              value={meeting.description}
              onChange={handleChange}
              placeholder="Description"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="datetime-local"
              name="date"
              className="form-control"
              value={meeting.date}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mt-2">
            {editMode ? 'Update' : 'Create'} Meeting
          </button>
        </form>
        <ul className="list-group">
  {meetings.map((meeting) => (
    <li key={meeting.id} className="list-group-item d-flex justify-content-between align-items-center">
      <div>
        <h5>{meeting.title}</h5>
        <p>{new Date(meeting.date).toLocaleString()}</p>
      </div>
      <div>
        <button onClick={() => handleEdit(meeting)} className="btn btn-warning btn-sm mr-2">Edit</button>
        <button onClick={() => handleDelete(meeting.id)} className="btn btn-danger btn-sm mr-2">Delete</button>
        <button onClick={() => handleMeetingSelect(meeting.id)} className="btn btn-info btn-sm">Manage Agenda</button>
		<button onClick={() => handleGenerateAgenda(meeting.id)} className="btn btn-secondary btn-sm">Generate Agenda</button> 
        <button onClick={() => handleGenerateMinutes(meeting.id)} className="btn btn-secondary btn-sm">Generate Minutes</button> {/* Updated line */}
      </div>
    </li>
  ))}
</ul>
      </div>
      <div className="column column-right">
        {selectedMeetingId && (
          <AgendaManagement
            meetingId={selectedMeetingId}
            onAgendaSelect={handleAgendaSelect}
            selectedAgendaId={selectedAgendaId}
          />
        )}
      </div>
    </div>
  );
}

export default MeetingManagement;
