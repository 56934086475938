import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

function MembersList() {
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loggedInRole, setLoggedInRole] = useState(null);


  const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  setLoggedInRole(role); // Set the role in your state
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};

  const fetchMembers = useCallback(async () => { //async () => {
	  checkLoginStatus();
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/members/all', {
         withCredentials: true, // Include cookies in requests
      });
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  },[]);
  
   useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);


  const deleteMember = async (memberId) => {
    try {
      await axios.delete(`https://api.mymeetingmanagement.com/api/members/delete/${memberId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setMembers(members.filter((member) => member.member_id !== memberId));
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const deleteSelectedMembers = async () => {
    try {
      await Promise.all(
        selectedMembers.map(async (memberId) => {
          await axios.delete(`https://api.mymeetingmanagement.com/api/members/delete/${memberId}`, {
             withCredentials: true, // Include cookies in requests
          });
        })
      );
      setMembers(members.filter((member) => !selectedMembers.includes(member.member_id)));
      setSelectedMembers([]);
    } catch (error) {
      console.error('Error deleting selected members:', error);
    }
  };

  const handleSelectMember = (memberId) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(memberId)
        ? prevSelected.filter((id) => id !== memberId)
        : [...prevSelected, memberId]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedMembers(members.map((member) => member.member_id));
    } else {
      setSelectedMembers([]);
    }
  };

  if (loggedInRole !== 'organizer') {
        return <label htmlFor="title">You do not have permission to perform this action</label>
      }
	return (
    <div>
      <h2>Members List</h2>
      <button onClick={deleteSelectedMembers}>Delete Selected</button>
      <table>
        <thead>
          <tr>
            <th><input type="checkbox" onChange={handleSelectAll} checked={selectedMembers.length === members.length} /></th>
            <th>Member ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {members.length > 0 ? (
            members.map((member) => (
              <tr key={member.member_id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedMembers.includes(member.member_id)}
                    onChange={() => handleSelectMember(member.member_id)}
                  />
                </td>
                <td>{member.member_id}</td>
                <td>{member.name}</td>
                <td>{member.email}</td>
                <td>{member.role}</td>
                <td>
                  <button onClick={() => deleteMember(member.member_id)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No members found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MembersList;

