import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import Login from './Login';
import './VotingPage.css'; // Reuse the same CSS for styling
import './RunMeetingPage.css'; // Import the CSS from RunMeetingPage for styling

const PublicVotingBooth = ({ setHeaderTitle }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [currentVotingItem, setCurrentVotingItem] = useState(null);
  const [voteCast, setVoteCast] = useState(false);
  const [remainingVotes, setRemainingVotes] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [socket, setSocket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [votingClosed, setVotingClosed] = useState(false);
  const [voteMessage, setVoteMessage] = useState('');
  const [loggedInMeeting, setLoggedInMeeting] = useState(null);
  const [memberId, setMemberId] = useState('');
  const [invalidMember, setInvalidMember] = useState(false);
  const [viewingBio, setViewingBio] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [tenantId, setTenantId] = useState(null);

  const onLoginSuccess = (loggedInMemberId, loggedInTenantId) => {
	setMemberId(loggedInMemberId); // Set the member ID after login
	setTenantId(loggedInTenantId); // Set the tenant ID after login
	setIsLoggedIn(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchStaff = async () => {
        try {
          console.log('Fetching staff data');
          const response = await axios.get('https://api.mymeetingmanagement.com/api/meetings/staff', {
            withCredentials: true, // Ensure cookies are sent with the request
          });
          if (response.data && response.data.length > 0) {
            const activeMeetings = response.data.filter(meeting => meeting.is_active);
            setIsStaff(true); // User is staff for at least one meeting

            // Connect to the first active meeting
            const activeMeeting = activeMeetings[0];
            setLoggedInMeeting(activeMeeting.id);
            setHeaderTitle(activeMeeting.title); // Update the header title

            const newSocket = io('https://api.mymeetingmanagement.com', {
				withCredentials: true,
              query: { meetingId: activeMeeting.id, tenantId }
            });
            setSocket(newSocket);

            newSocket.on('connect', () => {
              console.log('Connected to WebSocket server');
            });

            newSocket.on('disconnect', () => {
              console.log('Disconnected from WebSocket server');
            });

            newSocket.on('broadcastUpdate', (data) => {
              if (data.type === 'NEXT_VOTING_ITEM') {
                setCurrentVotingItem(data);
                setVoteCast(false);
                setSelectedOption('');
                setVotingClosed(!data.votingOpen); // Set votingClosed based on votingOpen status
              } else if (data.type === 'REMAINING_VOTES') {
                setRemainingVotes(data.remainingVotes);
              } else if (data.type === 'VOTING_CLOSED') {
                setVotingClosed(true);
              } else if (data.type === 'VOTE_RESULTS') {
                setVoteMessage(data.results);
              }
            });

            newSocket.on('connect_error', (error) => {
              console.error('WebSocket connection error:', error);
              setErrorMessage(error.message);
            });

            return () => {
              newSocket.close();
            };
          } else {
            setIsStaff(false); // User is not staff for any active meetings
            setErrorMessage('No active meeting found for the staff');
          }
        } catch (error) {
          console.error('Error fetching staff info:', error);
          setIsStaff(false);
        }
      };
      fetchStaff();
    }
  }, [isLoggedIn, setHeaderTitle]);

  // Effect to check remainingVotes and set votingClosed if necessary
  useEffect(() => {
    if (remainingVotes === 0) {
      setVotingClosed(true);
    }
  }, [remainingVotes]);

  useEffect(() => {
    if (votingClosed) {
      setVoteMessage('Voting is now closed');
    }
  }, [votingClosed]);

  const handleRetryLogin = async() => {
	  try {
    const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/logout', {}, {
      withCredentials: true, // Include credentials to ensure cookies are sent
    });
    setIsLoggedIn(false);
    setCurrentVotingItem(null);
    setVoteCast(false);
    setRemainingVotes(null);
    setSelectedOption('');
    setErrorMessage('');
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
    setHeaderTitle('Meeting Voting System');
  }catch (error) {
    console.error('Error logging out:', error.response || error.message);
	  }
  };
  

  const handleVote = async () => {
    try {
      // Validate the member ID input
      const attendeeResponse = await axios.get('https://api.mymeetingmanagement.com/api/attendees', {
        withCredentials: true, // Ensure cookies are sent with the request
      });

      const activeAttendee = attendeeResponse.data.find(a => a.meeting_id === loggedInMeeting);
      if (!activeAttendee) {
        setVoteMessage('Member ID is not valid for the current meeting.');
        setInvalidMember(true);
        // Reset the page for the next member to vote after 2 seconds
        setTimeout(() => {
          setVoteCast(false);
          setSelectedOption('');
          setVoteMessage('');
          setErrorMessage('');
          setMemberId(''); // Clear member ID input field
          setInvalidMember(false);
        }, 2000);
        return;
      }

      const voteData = {
        votingItemId: currentVotingItem.id,
        userId: memberId,
        tenantId: tenantId,
        meetingId: loggedInMeeting
      };

      if (currentVotingItem.category === 'Question' || currentVotingItem.category === 'Motion') {
        voteData.vote = selectedOption;
      } else {
        voteData.choiceId = selectedOption;
      }

      console.log('Sending vote data:', voteData); // Add this line for debugging

      await axios.post('https://api.mymeetingmanagement.com/api/voting/cast', voteData, {
        withCredentials: true, // Ensure cookies are sent with the request
      });
      setVoteCast(true);
      setVoteMessage('Thank you, your vote has been cast.'); // Success message

      // Fetch remaining votes from the server after casting a vote
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/voting/remaining-votes/${currentVotingItem.id}`, {
        withCredentials: true, // Ensure cookies are sent with the request
      });
      setRemainingVotes(response.data.remainingVotes);
      setMemberId(''); // Clear member ID input field after vote

      // Reset the page for the next member to vote after 2 seconds
      setTimeout(() => {
        setVoteCast(false);
        setSelectedOption('');
        setVoteMessage('');
        setErrorMessage('');
      }, 2000);

    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.message === 'Your vote has already been recorded') {
          setVoteCast(true);
          setVoteMessage('Your vote has already been recorded.');
          // Reset the page for the next member to vote after 2 seconds
          setTimeout(() => {
            setVoteCast(false);
            setSelectedOption('');
            setVoteMessage('');
            setErrorMessage('');
            setMemberId(''); // Clear member ID input field
          }, 2000);
        } else {
          setErrorMessage(error.response.data.message);
        }
      } else {
        console.error('Error casting vote:', error);
      }
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleViewBio = (candidate) => {
    setSelectedCandidate(candidate);
    setViewingBio(true);
  };

  const handleReturnToVote = () => {
    setViewingBio(false);
    setSelectedCandidate(null);
  };

  if (!isLoggedIn) {
    return <Login onLoginSuccess={onLoginSuccess} />;
  }

  if (errorMessage) {
    return (
      <div className="error-page">
        <p>{errorMessage}</p>
        <button className="retry-button" onClick={handleRetryLogin}>Retry Login</button>
      </div>
    );
  }

  if (!isStaff) {
    return (
      <div className="error-page">
        <p>You are not nominated as Staff for any active meeting, See your meeting organiser</p>
        <button className="retry-button" onClick={handleRetryLogin}>Retry Login</button>
      </div>
    );
  }

  if (!currentVotingItem) {
    return <p>Waiting for meeting to commence...</p>;
  }

  if (viewingBio && selectedCandidate && currentVotingItem.category === 'CandidateVote') {
    return (
      <div className="bio-view-page">
        <img src={selectedCandidate.profile_picture} alt={`${selectedCandidate.choice_text}'s profile`} className="candidate-picture" />
        <h2 className="candidate-name">{selectedCandidate.choice_text}</h2>
        <div className="candidate-bio" dangerouslySetInnerHTML={{ __html: selectedCandidate.bio }}></div>
        <button onClick={handleReturnToVote} className="return-button">Return to Vote</button>
      </div>
    );
  }

  return (
    <div className="voting-page">
      <h1>{currentVotingItem.title}</h1>
      <h2>{currentVotingItem.question}</h2>
      {!voteCast && !invalidMember ? (
        !votingClosed ? (
          <div className="voting-options">
            {currentVotingItem.category === 'Question' || currentVotingItem.category === 'Motion' ? (
              <>
                <label>
                  <input
                    type="radio"
                    value="yes"
                    checked={selectedOption === 'yes'}
                    onChange={handleOptionChange}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    value="no"
                    checked={selectedOption === 'no'}
                    onChange={handleOptionChange}
                  />
                  No
                </label>
                <label>
                  <input
                    type="radio"
                    value="abstain"
                    checked={selectedOption === 'abstain'}
                    onChange={handleOptionChange}
                  />
                  Abstain
                </label>
              </>
            ) : currentVotingItem.category === 'CandidateVote' ? (
              currentVotingItem.choices && currentVotingItem.choices.map(choice => (
                <div key={choice.id} className="candidate-option">
                  <label>
                    <input
                      type="radio"
                      value={choice.id}
                      checked={selectedOption === String(choice.id)}
                      onChange={handleOptionChange}
                    />
                    {choice.choice_text}
                  </label>
                  <button onClick={() => handleViewBio(choice)} className="view-bio-button">View Bio</button>
                </div>
              ))
            ) : (
              currentVotingItem.choices && currentVotingItem.choices.map(choice => (
                <label key={choice.id}>
                  <input
                    type="radio"
                    value={choice.id}
                    checked={selectedOption === String(choice.id)}
                    onChange={handleOptionChange}
                  />
                  {choice.choice_text}
                </label>
              ))
            )}
            <div className="input-group">
              <input
                type="text"
                className="form-control text-center prominent-outline" // Add prominent outline class
                placeholder="Enter Member ID"
                value={memberId}
                onChange={(e) => setMemberId(e.target.value)}
              />
            </div>
            <button className="vote-button" onClick={handleVote} disabled={!selectedOption || !memberId}>Cast Vote</button>
          </div>
        ) : (
          <p>Voting is now closed</p>
        )
      ) : (
        <p>{voteMessage || 'Your vote has been cast, thank you!'}</p>
      )}
      <div className="logout-button-container">
        <button className="logout-button" onClick={handleRetryLogin}>Logout</button>
      </div>
    </div>
  );
};

export default PublicVotingBooth;

