import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

function MeetingForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  //const [tenantId, setTenantId] = useState(''); // Add state for tenant ID
  const [message, setMessage] = useState('');
    
  const [loggedInRole, setLoggedInRole] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  
   useEffect(() => {
    checkLoginStatus();
  }, []);
  
  const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  setLoggedInRole(role); // Set the role in your state
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.mymeetingmanagement.com/api/meetings', {
        title,
        description,
        date,
      }, {
        withCredentials: true, // Include cookies in the request
      });

      console.log(response.data);
      setMessage('Meeting created successfully!');
      setTitle('');
      setDescription('');
      setDate('');
    } catch (error) {
      console.error(error);
      setMessage('Error creating meeting.');
    }
  };

  if (loggedInRole !== 'organizer') {
        return <label htmlFor="title">You do not have permission to perform this action</label>
      }
	  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <input
        type="datetime-local"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
      />
      <button type="submit">Create Meeting</button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default MeetingForm;


