import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Save the original console.error
const originalError = console.error;

// Override console.error
console.error = (...args) => {
  if (args[0].includes('Warning: findDOMNode is deprecated')) {
    return;
  }
  originalError(...args);
};

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default App;


