import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BulkRegister() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false); // Track processing state

  const [tenantId, setTenantId] = useState(null);
  const [loggedInRole, setLoggedInRole] = useState(null);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
        withCredentials: true,
      });

      if (response.status === 200) {
        const { role } = response.data; // Extract the role from the response data
        setLoggedInRole(role); // Set the role in your state
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleBulkRegister = async (e) => {
    e.preventDefault();

    if (isProcessing) {
      alert("Submission is already in progress, please wait for the existing process to finish before submitting again");
      return;
    }

    if (!file) {
      setMessage('Please upload a file.');
      return;
    }

    setIsProcessing(true); // Start processing
    setMessage('Processing...');

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://api.mymeetingmanagement.com/api/bulk-register', formData, {
        withCredentials: true, // Ensure cookies are sent with the request
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      let message = 'Bulk registration successful';
      if (response.data.duplicatesFound) {
        message += '. Duplicate members were identified and skipped.';
      }
      setMessage(message);
      setTimeout(() => setMessage(''), 2000); // Clear message after 2 seconds
    } catch (error) {
      const errorMessage = error.response ? error.response.data.error : error.message;
      console.error('Bulk registration error:', errorMessage);
      setMessage(`Error in bulk registration: ${errorMessage}`);
    } finally {
      setIsProcessing(false); // End processing
      setFile(null); // Clear file input after submission
    }
  };

  if (loggedInRole !== 'organizer') {
    return <label htmlFor="title">You do not have permission to perform this action</label>
  }

  return (
    <form onSubmit={handleBulkRegister}>
      <h2>Bulk Register</h2>
      <input type="file" onChange={handleFileChange} required />
      <button type="submit" disabled={isProcessing}>Bulk Register</button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default BulkRegister;

//WORKING CODE BEFORE MODIFYING FOR ONE PROCESS AT A TIME AND EXTRA MESSAGES
/* import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BulkRegister() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  
const [tenantId, setTenantId] = useState(null);
  const [loggedInRole, setLoggedInRole] = useState(null);
  
   useEffect(() => {
    checkLoginStatus();
  }, []);
  
  const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  setLoggedInRole(role); // Set the role in your state
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleBulkRegister = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://api.mymeetingmanagement.com/api/bulk-register', formData, {
		  withCredentials: true, // Ensure cookies are sent with the request
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Bulk registration successful');
      console.log('Bulk registration successful:', response.data);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.error : error.message;
      console.error('Bulk registration error:', errorMessage);
      setMessage(`Error in bulk registration: ${errorMessage}`);
    }
  };

  if (loggedInRole !== 'organizer') {
        return <label htmlFor="title">You do not have permission to perform this action</label>
      }
  return (
    <form onSubmit={handleBulkRegister}>
      <h2>Bulk Register</h2>
      <input type="file" onChange={handleFileChange} required />
      <button type="submit">Bulk Register</button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default BulkRegister; */


