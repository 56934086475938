import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import VotingItemsManagement from './VotingItemsManagement';

function AgendaManagement({ meetingId, onAgendaSelect, selectedAgendaId }) {
  const [agendaItems, setAgendaItems] = useState([]);
  const [agendaItem, setAgendaItem] = useState({ title: '', description: '' });
  const [meeting, setMeeting] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);

  const [tenantId, setTenantId] = useState(null);
  const [role, setRole] = useState(null);
  
const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  const { tenantId } = response.data; // Extract the role from the response data
		  setRole(role);
		  setTenantId(tenantId);
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};

  const fetchAgendaItems = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/agenda/${meetingId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setAgendaItems(response.data.sort((a, b) => a.order - b.order)); // Sort by order
    } catch (error) {
      console.error('Error fetching agenda items:', error);
    }
  }, [meetingId]);

  const fetchMeeting = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/${meetingId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setMeeting(response.data);
    } catch (error) {
      console.error('Error fetching meeting:', error);
    }
  }, [meetingId]);

  useEffect(() => {
    checkLoginStatus();
	fetchAgendaItems();
    fetchMeeting();
  }, [fetchAgendaItems, fetchMeeting]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgendaItem({ ...agendaItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (role !== 'organizer') {
        return alert('You do not have permission to perform this action.');
      }
      if (editMode) {
        await axios.put(`https://api.mymeetingmanagement.com/api/agenda/${editId}`, agendaItem, {
           withCredentials: true, // Include cookies in requests
        });
        setEditMode(false);
        setEditId(null);
      } else {
        await axios.post(`https://api.mymeetingmanagement.com/api/agenda/${meetingId}`, agendaItem, {
           withCredentials: true, // Include cookies in requests
        });
      }
      setAgendaItem({ title: '', description: '' });
      fetchAgendaItems();
    } catch (error) {
      console.error('Error saving agenda item:', error);
    }
  };

  const handleEdit = (item) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setAgendaItem(item);
    setEditMode(true);
    setEditId(item.id);
  };

  const handleDelete = async (id) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    try {
      await axios.delete(`https://api.mymeetingmanagement.com/api/agenda/${id}`, {
         withCredentials: true, // Include cookies in requests
      });
      fetchAgendaItems();
    } catch (error) {
      console.error('Error deleting agenda item:', error);
    }
  };

  const handleAgendaSelect = (agendaId) => {
    onAgendaSelect(agendaId);
  };

  const moveAgendaItem = async (id, direction) => {
    try {
      await axios.put(`https://api.mymeetingmanagement.com/api/agenda/${id}/order`, { direction }, {
         withCredentials: true, // Include cookies in requests
      });
      fetchAgendaItems(); // Refresh list after reorder
    } catch (error) {
      console.error('Error updating agenda item order:', error);
    }
  };

  return (
    <div className="section">
      <h2 className="text-center">Agenda Management</h2>
      {meeting && <h3 className="text-center" style={{ fontSize: '1rem' }}>{meeting.title}</h3>}
      {role === 'organizer' && (
        <form onSubmit={handleSubmit} className="mb-4 text-center">
          <div className="form-group">
            <label htmlFor="title">Agenda Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={agendaItem.title}
              onChange={handleChange}
              placeholder="Agenda Title"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              className="form-control"
              value={agendaItem.description}
              onChange={handleChange}
              placeholder="Description"
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mt-2">
            {editMode ? 'Update' : 'Create'} Agenda Item
          </button>
        </form>
      )}
      <ul className="list-group">
        {agendaItems.map((item, index) => (
          <li key={item.id} className="list-group-item d-flex align-items-center">
            {role === 'organizer' && (
              <div className="d-flex flex-column align-items-center mr-3">
                <button onClick={() => moveAgendaItem(item.id, 'up')} disabled={index === 0} className="btn btn-secondary btn-sm mb-1">
                  <i className="bi bi-chevron-up"></i>
                </button>
                <button onClick={() => moveAgendaItem(item.id, 'down')} disabled={index === agendaItems.length - 1} className="btn btn-secondary btn-sm">
                  <i className="bi bi-chevron-down"></i>
                </button>
              </div>
            )}
            <div className="flex-grow-1 text-center">
              <h5>{item.title}</h5>
              <p>{item.description}</p>
              {role === 'organizer' && (
                <>
                  <button onClick={() => handleEdit(item)} className="btn btn-warning btn-sm mr-2">Edit</button>
                  <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm mr-2">Delete</button>
                  <button onClick={() => handleAgendaSelect(item.id)} className="btn btn-info btn-sm">Manage Voting Items</button>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
      {selectedAgendaId && <VotingItemsManagement agendaId={selectedAgendaId} tenantId={tenantId} />}
    </div>
  );
}

export default AgendaManagement;


