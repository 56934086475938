//WORKING CODE BEFORE ADJUSTING Login
import React, { useState } from 'react';
import { Routes, Route, useLocation, } from 'react-router-dom';
import Register from './Register';
import Login from './Login';
import MeetingForm from './MeetingForm';
import MeetingsList from './MeetingsList';
import BulkRegister from './BulkRegister';
import MembersList from './MembersList';
import MeetingManagement from './MeetingManagement'; 
import VotingItemsManagement from './VotingItemsManagement'; 
import AgendaManagement from './AgendaManagement';
import VotingPage from './VotingPage';
import MemberActivation from './MemberActivation';
import PublicViewingPage from './PublicViewingPage';
import RunMeetingPage from './RunMeetingPage'; 
import PublicVotingBooth from './PublicVotingBooth';
import ProtectedRoute from './ProtectedRoute';
import DashboardPage from './DashboardPage';
import TenantRegistration from './TenantRegistration';
import Success from './Success';
import Cancel from './Cancelpg';
import './App.css';

const NavigationMenu = () => {
	  
  return (
    <nav className="App-nav">
      <ul>
	    <li><a href="/register">Register</a></li>
        <li><a href="/bulk-register">Bulk Register</a></li>
        <li><a href="/members">Members</a></li>
        <li><a href="/manage-meetings">Manage Meetings</a></li>
        <li><a href="/run-meeting">Run Meeting</a></li>
		<li><a href="/login">Logout</a></li>
      </ul>
    </nav>
  );
};


const AppWrapper = () => {
  const [headerTitle, setHeaderTitle] = useState('Meeting Management System');
  const location = useLocation();
  
  
  const isStandalonePage = location.pathname === '/activate-member' || location.pathname === '/public-view' || location.pathname === '/vote' || location.pathname === '/voting-booth' || location.pathname === '/login' || location.pathname === '/create-account';
  
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>{headerTitle}</h1>
      </header>
      {!isStandalonePage && <NavigationMenu />}
      <main className="App-main">
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/bulk-register" element={<BulkRegister />} />
          <Route path="/create-meeting" element={<MeetingForm />} />
          <Route path="/meetings" element={<MeetingsList />} />
          <Route path="/members" element={<MembersList />} />
          <Route path="/manage-meetings" element={<MeetingManagement />} />
          <Route path="/agenda/:agendaId" element={<AgendaManagement />} /> 
          <Route path="/voting-items/:agendaId" element={<VotingItemsManagement />} />
          <Route path="/vote" element={<VotingPage setHeaderTitle={setHeaderTitle} />} />
          <Route path="/activate-member" element={<MemberActivation setHeaderTitle={setHeaderTitle} />} />
          <Route path="/public-view" element={<PublicViewingPage setHeaderTitle={setHeaderTitle} />} />
          <Route path="/run-meeting" element={<RunMeetingPage />} />
		  <Route path="/voting-booth" element={<PublicVotingBooth setHeaderTitle={setHeaderTitle} />} />
		  <Route path="/dashboard" element={<DashboardPage />} />
		  <Route path="/create-account" element={<TenantRegistration />} />
		  <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
		  <Route path="/" element={<ProtectedRoute> <DashboardPage /> </ProtectedRoute>}/>
        </Routes>
      </main>
    </div>
  );
};

export default AppWrapper;


