import React from 'react';
import { useLocation } from 'react-router-dom';

function Success() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tenantId = searchParams.get('tenantId');

  return (
    <div className="success-page">
      <h2>Registration Successful</h2>
      <p>Your Organization ID is <strong>{tenantId}</strong>. Please remember this ID for future logins.</p>
    </div>
  );
}

export default Success;