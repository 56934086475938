import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import './TenantRegistration.css'; // Make sure you have the relevant styles here

// Initialize Stripe with your public key
const stripePromise = loadStripe('pk_test_51Prwz5CjnqeFGvhHlRxbf3scZE6ACxRVJqkTYlPRWYeUP9aHBxUZtXdOtPLh6XWFefVlpmHNVBtSKpd9cy4GynN500gB9J15dz');

function TenantRegistration() {
  const [organizationName, setOrganizationName] = useState('');
  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [paymentPlan, setPaymentPlan] = useState('single');
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
		// Create the registrationData object with all the necessary fields
		const registrationData = {
		  organizationName,
		  memberId,
		  name,
		  email,
		  password,
		  confirmPassword,
		  paymentPlan,
		  quantity,
		};
      
	  const response = await axios.post('https://api.mymeetingmanagement.com/api/tenantRegistration/registerTenant', registrationData);

      const { sessionId } = response.data;

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Registration failed:', error);
      setLoading(false);
    }
  };

  return (
    <div className="tenant-registration">
      <h2>Register Your Organization</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Organization Name</label>
          <input
            type="text"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Member ID</label>
          <input
            type="text"
            value={memberId}
            onChange={(e) => setMemberId(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="First and Last Name"
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Payment Plan</label>
          <select value={paymentPlan} onChange={(e) => setPaymentPlan(e.target.value)}>
            <option value="single">Single Meeting - $20</option>
            <option value="subscription">Annual Subscription - $95</option>
          </select>
        </div>
        {paymentPlan === 'single' && (
          <div className="form-group">
            <label>Quantity</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              required
            />
          </div>
        )}
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Register and Pay'}
        </button>
      </form>
    </div>
  );
}

export default TenantRegistration;