import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomReactQuill = (props) => {
  const quillRef = useRef(null);

  useEffect(() => {
    // You can perform any necessary setup with the editor instance here if needed
  }, []);

  return <ReactQuill ref={quillRef} {...props} />;
};

export default CustomReactQuill;
