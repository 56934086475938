import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

function MeetingsList() {
  const [meetings, setMeetings] = useState([]);
  
  
  const [loggedInRole, setLoggedInRole] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  
  const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  setLoggedInRole(role); // Set the role in your state
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};

  const fetchMeetings = useCallback(async () => {
	  await checkLoginStatus();
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/meetings', {
        withCredentials: true,
		});
      // Sorting meetings by date descending (newest first)
      const sortedMeetings = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMeetings(sortedMeetings);
    } catch (error) {
      console.error('Error fetching meetings:', error);
    }
  },[tenantId]);;
  
   useEffect(() => { 
    fetchMeetings();
  }, [fetchMeetings]);

  const deleteMeeting = async (meetingId) => {
    try {
      await axios.delete(`https://api.mymeetingmanagement.com/api/meetings/${meetingId}`, {
        withCredentials: true,
		});
      setMeetings(meetings.filter((meeting) => meeting.id !== meetingId));
    } catch (error) {
      console.error('Error deleting meeting:', error);
    }
  };

  if (loggedInRole !== 'organizer') {
        return <label htmlFor="title">You do not have permission to perform this action</label>
      }
	  return (
    <div>
      <h2>Meetings List</h2>
      <ul>
        {meetings.length > 0 ? (
          meetings.map((meeting) => (
            <li key={meeting.id}>
              {meeting.title} - {new Date(meeting.date).toLocaleString()}
              <button onClick={() => deleteMeeting(meeting.id)}>Delete</button>
            </li>
          ))
        ) : (
          <li>No meetings found</li>
        )}
      </ul>
    </div>
  );
}

export default MeetingsList;


