import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import Login from './Login';
import './VotingPage.css';

const VotingPage = ({ setHeaderTitle }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [attendee, setAttendee] = useState(null);
  const [currentVotingItem, setCurrentVotingItem] = useState(null);
  const [voteCast, setVoteCast] = useState(false);
  const [remainingVotes, setRemainingVotes] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [socket, setSocket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [votingClosed, setVotingClosed] = useState(false);
  const [results, setResults] = useState(null);
  const [voteMessage, setVoteMessage] = useState('');
  const [loggedInMeeting, setLoggedInMeeting] = useState(null);
  const [viewingBio, setViewingBio] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const fetchedRemainingVotes = 1;

  const [memberId, setMemberId] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const statusChecked = false;

  const onLoginSuccess = (loggedInMemberId, loggedInTenantId) => {
	setMemberId(loggedInMemberId); // Set the member ID after login
	setTenantId(loggedInTenantId); // Set the tenant ID after login
	setIsLoggedIn(true);
  };


	
  useEffect(() => {
    if (isLoggedIn) {
      const fetchAttendee = async () => {
        try {
          const response = await axios.get('https://api.mymeetingmanagement.com/api/attendees', {
            withCredentials: true, // Ensure cookies are sent with the request
          });
          const activeAttendee = response.data.find(a => a.meeting_id);
          if (activeAttendee) {
            setAttendee(activeAttendee);
            const meetingId = activeAttendee.meeting_id;
            const meetingResponse = await axios.get(`https://api.mymeetingmanagement.com/api/meetings/${meetingId}`, {
              withCredentials: true, // Ensure cookies are sent with the request
            });
            setLoggedInMeeting(meetingId);
            setHeaderTitle(meetingResponse.data.title); // Update the header title

            const newSocket = io('https://api.mymeetingmanagement.com', {
				withCredentials: true,
              query: { meetingId, tenantId }
            });
            setSocket(newSocket);

            newSocket.on('connect', () => {
              console.log('Connected to WebSocket server');
            });

            newSocket.on('disconnect', () => {
              console.log('Disconnected from WebSocket server');
            });

            newSocket.on('broadcastUpdate', (data) => {
              if (data.type === 'NEXT_VOTING_ITEM') {
                setCurrentVotingItem(data);
                setVoteCast(false);
                setSelectedOption('');
                setVotingClosed(!data.votingOpen); // Set votingClosed based on votingOpen status
                setResults(null); // Reset results when a new voting item is received
              } else if (data.type === 'REMAINING_VOTES') {
                setRemainingVotes(data.remainingVotes);
              } else if (data.type === 'VOTING_CLOSED') {
                setVotingClosed(true);
              } else if (data.type === 'SHOW_RESULTS') {
                setResults(data.results);
              }
            });

            newSocket.on('connect_error', (error) => {
              console.error('WebSocket connection error:', error);
              setErrorMessage(error.message);
            });

            return () => {
              newSocket.close();
            };
          } else {
            setErrorMessage('No active meeting found for the attendee');
          }
        } catch (error) {
          console.error('Error fetching attendee info:', error);
          setErrorMessage(error.message);
        }
      };
      fetchAttendee();
    }
  }, [isLoggedIn, tenantId, memberId, setHeaderTitle]);

  useEffect(() => {
    if (remainingVotes === 0) {
      setVotingClosed(true);
    }
  }, [remainingVotes]);

  useEffect(() => {
    if (votingClosed) {
      setVoteMessage('Voting is now closed');
    }
  }, [votingClosed]);

  const handleRetryLogin = async() => {
	  try {
    const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/logout', {}, {
      withCredentials: true, // Include credentials to ensure cookies are sent
    });
    setIsLoggedIn(false);
    setAttendee(null);
    setCurrentVotingItem(null);
    setVoteCast(false);
    setRemainingVotes(null);
    setSelectedOption('');
    setErrorMessage('');
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
    setHeaderTitle('Meeting Voting System');
	  }catch (error) {
    console.error('Error logging out:', error.response || error.message);
	  }
  };

  const handleVote = async () => {
    try {
      const voteData = {
        votingItemId: currentVotingItem.id,
        userId: memberId,
        tenantId: tenantId,
        meetingId: loggedInMeeting
      };

      if (currentVotingItem.category === 'Question' || currentVotingItem.category === 'Motion') {
        voteData.vote = selectedOption;
      } else {
        voteData.choiceId = selectedOption;
      }

      console.log('Sending vote data:', voteData); // Add this line for debugging

      await axios.post('https://api.mymeetingmanagement.com/api/voting/cast', voteData, {
        withCredentials: true, // Ensure cookies are sent with the request
      });
      setVoteCast(true);
      setVoteMessage('Thank you, your vote has been cast.'); // Success message

      // Fetch remaining votes from the server after casting a vote
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/voting/remaining-votes/${currentVotingItem.id}`, {
        withCredentials: true, // Ensure cookies are sent with the request
      });
      setRemainingVotes(response.data.remainingVotes);

    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.message === 'Your vote has already been recorded') {
          setVoteCast(true);
          setVoteMessage('Your vote has already been recorded.');
        } else {
          setErrorMessage(error.response.data.message);
        }
      } else {
        console.error('Error casting vote:', error);
      }
    }
    console.log('remainingVotes variable is: ', remainingVotes);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleViewBio = (candidate) => {
    setSelectedCandidate(candidate);
    setViewingBio(true);
  };

  const handleReturnToVote = () => {
    setViewingBio(false);
    setSelectedCandidate(null);
  };

  // Use the fetched value directly to determine if voting should be closed
  if (fetchedRemainingVotes === 0) {
    setVotingClosed(true);
  }

  const generateResultMessage = (results) => {
    if (!results || Object.keys(results).length === 0) return 'No votes were cast for this item.';

    const yesVotes = results['yes'] || 0;
    const noVotes = results['no'] || 0;
    const abstainVotes = results['abstain'] || 0;
    const totalVotes = yesVotes + noVotes;

    if (currentVotingItem.category === 'Question' || currentVotingItem.category === 'Motion') {
      if (totalVotes === 0) {
        return 'No votes have been cast for this item.';
      }
      if (yesVotes > noVotes) {
        return `The YES's win with ${yesVotes} of ${totalVotes} votes, ${abstainVotes} members abstained.`;
      } else if (noVotes > yesVotes) {
        return `The NO's win with ${noVotes} of ${totalVotes} votes, ${abstainVotes} members abstained.`;
      } else {
        return `The vote is tied at ${yesVotes} each and ${abstainVotes} members abstained, therefore undecided.`;
      }
    } else if (currentVotingItem.question.toLowerCase().includes('candidate')) {
      const maxVotes = Math.max(...Object.values(results));
      const maxVoteChoices = Object.keys(results).filter(key => results[key] === maxVotes);
      if (maxVoteChoices.length > 1) {
        return 'The vote is tied and therefore undecided.';
      }
      const winningChoice = currentVotingItem.choices.find(choice => choice.id.toString() === maxVoteChoices[0])?.choice_text;
      const totalChoiceVotes = Object.values(results).reduce((sum, count) => sum + count, 0);
      return `The Membership has selected ${winningChoice} with the majority vote of ${maxVotes} votes out of ${totalChoiceVotes}.`;
    } else {
      const maxVotes = Math.max(...Object.values(results));
      const maxVoteChoices = Object.keys(results).filter(key => results[key] === maxVotes);
      if (maxVoteChoices.length > 1) {
        return 'The vote is tied and therefore undecided.';
      }
      const winningChoice = currentVotingItem.choices.find(choice => choice.id.toString() === maxVoteChoices[0])?.choice_text;
      const totalChoiceVotes = Object.values(results).reduce((sum, count) => sum + count, 0);
      return `${winningChoice} wins the vote with ${maxVotes} out of ${totalChoiceVotes} votes.`;
    }
  };

  const resultMessage = results ? generateResultMessage(results) : '';

  if (!isLoggedIn) {
    return <Login onLoginSuccess={onLoginSuccess} />;
  }

  if (errorMessage) {
    return (
      <div className="error-page">
        <p>{errorMessage}</p>
        <button className="retry-button" onClick={handleRetryLogin}>Retry Login</button>
      </div>
    );
  }

  if (!attendee) {
    return <p>You have not been registered as in attendance, please see door staff</p>;
  }

  if (!currentVotingItem) {
    return <p>Waiting for meeting to commence...</p>;
  }

  if (viewingBio && selectedCandidate && currentVotingItem.category === 'CandidateVote') {
    return (
      <div className="bio-view-page">
        <img src={selectedCandidate.profile_picture} alt={`${selectedCandidate.choice_text}'s profile`} className="candidate-picture" />
        <h2 className="candidate-name">{selectedCandidate.choice_text}</h2>
        <div className="candidate-bio" dangerouslySetInnerHTML={{ __html: selectedCandidate.bio }}></div>
        <button onClick={handleReturnToVote} className="return-button">Return to Vote</button>
      </div>
    );
  }

  return (
    <div className="voting-page">
      <h1>{currentVotingItem.title}</h1>
      <h2>{currentVotingItem.question}</h2>
      {!voteCast ? (
        !votingClosed ? (
          <div className="voting-options">
            {currentVotingItem.category === 'Question' || currentVotingItem.category === 'Motion' ? (
              <>
                <label>
                  <input
                    type="radio"
                    value="yes"
                    checked={selectedOption === 'yes'}
                    onChange={handleOptionChange}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    value="no"
                    checked={selectedOption === 'no'}
                    onChange={handleOptionChange}
                  />
                  No
                </label>
                <label>
                  <input
                    type="radio"
                    value="abstain"
                    checked={selectedOption === 'abstain'}
                    onChange={handleOptionChange}
                  />
                  Abstain
                </label>
              </>
            ) : currentVotingItem.category === 'CandidateVote' ? (
              currentVotingItem.choices && currentVotingItem.choices.map(choice => (
                <div key={choice.id} className="candidate-option">
                  <label>
                    <input
                      type="radio"
                      value={choice.id}
                      checked={selectedOption === String(choice.id)}
                      onChange={handleOptionChange}
                    />
                    {choice.choice_text}
                  </label>
                  <button onClick={() => handleViewBio(choice)} className="view-bio-button">View Bio</button>
                </div>
              ))
            ) : (
              currentVotingItem.choices && currentVotingItem.choices.map(choice => (
                <label key={choice.id}>
                  <input
                    type="radio"
                    value={choice.id}
                    checked={selectedOption === String(choice.id)}
                    onChange={handleOptionChange}
                  />
                  {choice.choice_text}
                </label>
              ))
            )}
            <button className="vote-button" onClick={handleVote} disabled={!selectedOption}>Cast Vote</button>
          </div>
        ) : (
          results ? <p>{resultMessage}</p> : <p>Voting is now closed</p>
        )
      ) : (
        <>
          <p>{voteMessage || 'Your vote has been cast, thank you!'}</p>
          {results && <p>{resultMessage}</p>}
        </>
      )}
      <div className="logout-button-container">
        <button className="logout-button" onClick={handleRetryLogin}>Logout</button>
      </div>
    </div>
  );
};

export default VotingPage;


