import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function Login({ onLoginSuccess }) {
  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [message, setMessage] = useState('');
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
          withCredentials: true,
        });
        if (response.status === 200) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/login', {
        member_id: memberId,
        name,
        password,
		tenantId
      }, {
		withCredentials: true, // Include cookies in requests
		});

      const { tenantId: responseTenantId, memberId: responseMemberId } = response.data;

      setMessage('Login successful');
	  
      setIsLoggedIn(true);

      // Determine where to navigate based on the current path
      if (location.pathname === '/login') {
        navigate('/dashboard');
      }

      console.log('Login successful');
      if (onLoginSuccess) {
        onLoginSuccess(responseMemberId, responseTenantId);
      }
    } catch (error) {
      console.error('Error:', error.response || error.message);
      setMessage('Error logging in');
    }
  };
  
  
  const handleLogout = async () => {
  try {
    const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/logout', {}, {
      withCredentials: true, // Include credentials to ensure cookies are sent
    });

    if (response.status === 200) {
      // Remove any additional client-side state

      setIsLoggedIn(false);
      if (location.pathname === '/login') {
      navigate('/login');
	}
      console.log('Logout successful');
    }
  } catch (error) {
    console.error('Error logging out:', error.response || error.message);
  }
};


  if (isLoggedIn) {
    return (
      <div>
        <button className="btn btn-primary" onClick={handleLogout}>Logout</button>
      </div>
    );
  }

   return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Member ID</label>
          <input type="text" className="form-control" value={memberId} onChange={(e) => setMemberId(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Name</label>
          <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
		<div className="form-group">
          <label>Password</label>
          <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
		<div className="form-group">
          <label>Organisation ID</label>
          <input type="text" className="form-control" value={tenantId} onChange={(e) => setTenantId(e.target.value)} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
		<button type="submit" className="btn btn-primary">Login</button>
		</div>
      </form>
	   <label>{message && <p>{message}</p>}</label>
    </div>
  );
};

export default Login;

