import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Register() {
  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('member');
  const [tenantId, setTenantId] = useState(null);
  const [loggedInRole, setLoggedInRole] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
        withCredentials: true,
      });

      if (response.status === 200) {
        const { role } = response.data;
        setLoggedInRole(role);
        setRole(role);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/register', {
        member_id: memberId,
        name,
        email,
        password,
        role,
        tenant_id: tenantId
      }, {
        withCredentials: true,
      });

      console.log('User registered:', response.data);
      setSuccessMessage('User registered successfully!');
      
      // Clear the input fields after success
      setMemberId('');
      setName('');
      setEmail('');
      setPassword('');
      setRole('member');

      // Remove the success message after 2 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);
    } catch (error) {
      setError('User registration failed');

      // Remove the error message after 2 seconds
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };

  if (loggedInRole !== 'organizer') {
    return <label htmlFor="title">You do not have permission to perform this action</label>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Member ID"
        value={memberId}
        onChange={(e) => setMemberId(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        <option value="member">Member</option>
        <option value="organizer">Organizer</option>
      </select>
      <button type="submit">Register</button>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {error && <p className="error-message">{error}</p>}
    </form>
  );
}

export default Register;

/* import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Register() {
  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('member');
  //const [tenantId, setTenantId] = useState(''); // Add state for tenant ID
  const [error, setError] = useState(null);
    
  
  const [tenantId, setTenantId] = useState(null);
  const [loggedInRole, setLoggedInRole] = useState(null);
  
   useEffect(() => {
    checkLoginStatus();
  }, []);
  
  const checkLoginStatus = async () => {
	  try {
		const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
		  withCredentials: true,
		});

		if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
		  setLoggedInRole(role); // Set the role in your state
		  setRole(role);
		}
	  } catch (error) {
		console.error('Error checking login status:', error);
	  }
	};
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await axios.post('https://api.mymeetingmanagement.com/api/auth/register', {
        member_id: memberId,
        name,
        email,
        password,
        role,
		tenant_id: tenantId
      }, {
         withCredentials: true, // Include cookies in requests
      });

      console.log('User registered:', response.data);
    } catch (error) {
      setError('User registration failed');
    }
  };

  
  if (loggedInRole !== 'organizer') {
        return <label htmlFor="title">You do not have permission to perform this action</label>
      }
	  
	  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Member ID"
        value={memberId}
        onChange={(e) => setMemberId(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        <option value="member">Member</option>
        <option value="organizer">Organizer</option>
      </select>
      <button type="submit">Register</button>
      {error && <p>{error}</p>}
    </form>
  );
}

export default Register; */

