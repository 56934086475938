import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CustomReactQuill from './CustomReactQuill';
import { useDropzone } from 'react-dropzone';

function VotingItemsManagement({ agendaId }) {
  const [votingItems, setVotingItems] = useState([]);
  const [votingItem, setVotingItem] = useState({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
  const [choiceText, setChoiceText] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [agendaItem, setAgendaItem] = useState(null);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [version, setVersion] = useState(0);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState(null);

  const [role, setRole] = useState(null);
  
  const checkLoginStatus = async () => {
      try {
        const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
          withCredentials: true,
        });
        if (response.status === 200) {
          const { role } = response.data; // Extract the role from the response data
          setRole(role);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };
  
  const fetchVotingItems = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/voting-items/agenda/${agendaId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setVotingItems(response.data.sort((a, b) => a.order - b.order)); // Sort by order
    } catch (error) {
      console.error('Error fetching voting items:', error);
    }
  }, [agendaId]);

  const fetchAgendaItem = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/agenda/item/${agendaId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setAgendaItem(response.data);
    } catch (error) {
      console.error('Error fetching agenda item:', error);
    }
  }, [agendaId]);

  useEffect(() => {
    checkLoginStatus();
    fetchVotingItems();
    fetchAgendaItem();
  }, [fetchVotingItems, fetchAgendaItem, version]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVotingItem({ ...votingItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    try {
      if (editMode) {
        await axios.put(`https://api.mymeetingmanagement.com/api/voting-items/${editId}`, {
          withCredentials: true, // Include cookies in requests
          ...votingItem,
          choices: votingItem.category === 'MultipleChoice' || votingItem.category === 'CandidateVote'
            ? votingItem.choices.map(choice => ({
                choice_text: choice.choice_text || choice,
                profile_picture: choice.profile_picture || '',
                bio: choice.bio || ''
              }))
            : []
        }, {
           withCredentials: true, // Include cookies in requests
        });
        setEditMode(false);
        setEditId(null);
      } else {
        const uniqueChoices = [...new Set(votingItem.choices.map(choice => ({
          choice_text: choice.choice_text || choice,
          profile_picture: choice.profile_picture || '',
          bio: choice.bio || ''
        })))];
        await axios.post(`https://api.mymeetingmanagement.com/api/voting-items/${agendaId}`, {
          withCredentials: true, // Include cookies in requests
          ...votingItem,
          choices: votingItem.category === 'MultipleChoice' || votingItem.category === 'CandidateVote'
            ? uniqueChoices
            : []
        }, {
           withCredentials: true, // Include cookies in requests
        });
      }
      setVotingItem({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
      setChoiceText('');
      fetchVotingItems();
      setVersion(prevVersion => prevVersion + 1);
    } catch (error) {
      console.error('Error saving voting item:', error);
    }
  };

  const handleCancelUpdate = () => {
    setVotingItem({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
    setChoiceText('');
    setEditMode(false);
    setEditId(null);
  };

  const handleAddChoice = () => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    if (choiceText.trim() !== '') {
      const newChoice = { choice_text: choiceText.trim(), id: Date.now() }; // Temporary ID
      const uniqueChoices = [...new Set([...votingItem.choices, newChoice])];
      setVotingItem({ ...votingItem, choices: uniqueChoices });
      setChoiceText('');
    }
  };

  const handleEdit = (item) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    if ((item.category === 'MultipleChoice' || item.category === 'CandidateVote') && item.choices) {
      item.choices = item.choices.map(choice => ({
        choice_text: choice.choice_text || choice,
        profile_picture: choice.profile_picture || '',
        bio: choice.bio || '',
        id: choice.id // Ensure each choice has a valid id
      }));
    }
    setVotingItem(item);
    setEditMode(true);
    setEditId(item.id);
    setSelectedChoices([]); // Reset selected choices when entering edit mode
  };

  const handleDelete = async (id) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    try {
      await axios.delete(`https://api.mymeetingmanagement.com/api/voting-items/${id}`, {
         withCredentials: true, // Include cookies in requests
      });
      fetchVotingItems();
    } catch (error) {
      console.error('Error deleting voting item:', error);
    }
  };

  const handleSelectChoice = (choice) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setSelectedChoices(prevSelectedChoices =>
      prevSelectedChoices.includes(choice)
        ? prevSelectedChoices.filter(c => c !== choice)
        : [...prevSelectedChoices, choice]
    );
  };

  const handleDeleteSelectedChoices = () => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setVotingItem(prevVotingItem => ({
      ...prevVotingItem,
      choices: prevVotingItem.choices.filter(choice => !selectedChoices.includes(choice)),
    }));
    setSelectedChoices([]);
  };

  const handleEditCandidate = (index) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setEditingChoiceIndex(index);
  };

  const handleSaveBio = async (index, bio) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    const choice = votingItem.choices[index];
    const updatedChoices = votingItem.choices.map((c, i) =>
      i === index ? { ...c, bio } : c
    );
    setVotingItem({ ...votingItem, choices: updatedChoices });
    setEditingChoiceIndex(null);

    try {
      await axios.put(`https://api.mymeetingmanagement.com/api/choices/${choice.id}`, {
        choice_text: choice.choice_text,
        bio: bio,
        profile_picture: choice.profile_picture,
      }, {
         withCredentials: true, // Include cookies in requests
      });
    } catch (error) {
      console.error('Error saving bio:', error);
    }
  };

  const handleFileUpload = async (acceptedFiles, index) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const updatedChoices = votingItem.choices.map((c, i) =>
        i === index ? { ...c, profile_picture: reader.result } : c
      );
      setVotingItem({ ...votingItem, choices: updatedChoices });

      const choice = updatedChoices[index];
      console.log('Uploading profile picture for choice:', choice);
      if (choice && choice.id) {
        console.log('Updating choice with ID:', choice.id);
        try {
          await axios.put(`https://api.mymeetingmanagement.com/api/choices/${choice.id}`, {
            choice_text: choice.choice_text,
            bio: choice.bio,
            profile_picture: reader.result,
          }, {
             withCredentials: true, // Include cookies in requests
          });
        } catch (error) {
          console.error('Error uploading profile picture:', error);
        }
      } else {
        console.error('Choice ID is undefined or invalid.');
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0 && editingChoiceIndex !== null) {
        handleFileUpload(acceptedFiles, editingChoiceIndex);
      }
    }
  });

  const moveVotingItem = async (id, direction) => {
    try {
      await axios.put(`https://api.mymeetingmanagement.com/api/voting-items/${id}/order`, { direction }, {
         withCredentials: true, // Include cookies in requests
      });
      fetchVotingItems(); // Refresh list after reorder
    } catch (error) {
      console.error('Error updating voting item order:', error);
    }
  };

return (
  <div className="section">
    <h2>Voting Items Management</h2>
    {agendaItem && <h3 style={{ fontSize: '1rem' }}>{agendaItem.title}</h3>}
    <form onSubmit={handleSubmit}>
      <h3>Add New Voting Item</h3>
      <div className="form-group">
        <label>Category</label>
        <select name="category" className="form-control" value={votingItem.category} onChange={(e) => setVotingItem({ ...votingItem, category: e.target.value })}>
          <option value="Question">Question</option>
          <option value="Motion">Motion</option>
          <option value="MultipleChoice">Multiple Choice</option>
          <option value="CandidateVote">Candidate Vote</option>
        </select>
      </div>
      <div className="form-group">
        <label>Question/Motion</label>
        <input type="text" name="question" className="form-control" value={votingItem.question} onChange={(e) => setVotingItem({ ...votingItem, question: e.target.value })} />
      </div>
      {votingItem.category === 'Motion' && (
        <>
          <div className="form-group">
            <label>Raised By</label>
            <input type="text" name="raised_by" className="form-control" value={votingItem.raised_by} onChange={(e) => setVotingItem({ ...votingItem, raised_by: e.target.value })} />
          </div>
          <div className="form-group">
            <label>Seconded By</label>
            <input type="text" name="seconded_by" className="form-control" value={votingItem.seconded_by} onChange={(e) => setVotingItem({ ...votingItem, seconded_by: e.target.value })} />
          </div>
        </>
      )}
      {votingItem.category === 'MultipleChoice' && (
        <>
          <div className="form-group">
            <label>Add Choice</label>
            <input
              type="text"
              name="choiceText"
              className="form-control"
              value={choiceText}
              onChange={(e) => setChoiceText(e.target.value)}
              placeholder="Choice Text"
            />
            <button
              type="button"
              className="btn btn-secondary mt-2"
              onClick={() => {
                const updatedChoices = [...votingItem.choices, { choice_text: choiceText }];
                setVotingItem({ ...votingItem, choices: updatedChoices });
                setChoiceText(''); // Clear the input after adding the choice
              }}
            >
              Add Choice
            </button>
            <ul className="list-group mt-2">
              {votingItem.choices.map((choice, index) => (
                <li key={index} className="list-group-item">
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      checked={selectedChoices.includes(choice)}
                      onChange={() => handleSelectChoice(choice)}
                      className="mr-2 choice-checkbox"
                    />
                    <span className="flex-grow-1">{choice.choice_text}</span>
                  </div>
                </li>
              ))}
            </ul>
            <button type="button" className="btn btn-danger mt-2" onClick={handleDeleteSelectedChoices} disabled={selectedChoices.length === 0}>
              Delete Selected Choices
            </button>
          </div>
        </>
      )}
      {votingItem.category === 'CandidateVote' && (
        <>
          <div className="form-group">
            <label>Add Candidate Name</label>
            <input
              type="text"
              name="choiceText"
              className="form-control mb-2"
              value={choiceText}
              onChange={(e) => setChoiceText(e.target.value)}
              placeholder="Candidate Name"
            />
            <button
              type="button"
              className="btn btn-secondary mt-2"
              onClick={() => {
                const updatedChoices = [...votingItem.choices, { choice_text: choiceText, bio: '' }];
                setVotingItem({ ...votingItem, choices: updatedChoices });
                setChoiceText(''); // Clear the input after adding the candidate
              }}
            >
              Add Candidate
            </button>
          </div>
          <div className="form-group">
            <label>Choices</label>
            {votingItem.choices.map((choice, index) => (
              <div key={index} className="choice-container mb-2">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={selectedChoices.includes(choice)}
                    onChange={() => handleSelectChoice(choice)}
                    className="mr-2 choice-checkbox"
                  />
                  <span className="flex-grow-1">{choice.choice_text}</span>
                  <button
                    type="button"
                    className="btn btn-secondary ml-2"
                    onClick={() => setEditingChoiceIndex(editingChoiceIndex === index ? null : index)}
                  >
                    Edit Bio
                  </button>
                </div>
                {editingChoiceIndex === index && (
                  <div className="mt-2 bio-editor-container">
                    <CustomReactQuill
                      value={choice.bio || ''}
                      onChange={(value) => {
                        const updatedChoices = votingItem.choices.map((c, i) =>
                          i === index ? { ...c, bio: value } : c
                        );
                        setVotingItem({ ...votingItem, choices: updatedChoices });
                      }}
                      placeholder="Enter candidate bio"
                    />
                    <div {...getRootProps()} className="btn btn-secondary mt-2">
                      <input {...getInputProps()} />
                      Upload Profile Picture
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      <div className="d-flex justify-content-between align-items-center mt-2">
        <button type="submit" className="btn btn-primary mt-2 mr-2">
          {editMode ? 'Update' : 'Create'} Voting Item
        </button>
        {editMode && (
          <button type="button" onClick={handleCancelUpdate} className="btn btn-danger">
            Cancel Update
          </button>
        )}
      </div>
    </form>
    <ul className="list-group">
      {votingItems.map((item, index) => (
        <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-center mr-3 order-buttons-container">
            <button onClick={() => moveVotingItem(item.id, 'up')} disabled={index === 0} className="btn btn-secondary btn-sm mb-1">
              <i className="bi bi-chevron-up"></i>
            </button>
            <button onClick={() => moveVotingItem(item.id, 'down')} disabled={index === votingItems.length - 1} className="btn btn-secondary btn-sm">
              <i className="bi bi-chevron-down"></i>
            </button>
          </div>
          <div className="flex-grow-1">
            <div className="question-container">
              <span className="question-category">{item.category}:</span> <span className="question-text">{item.question}</span>
            </div>
            {item.category === 'Motion' && (
              <>
                <br />
                <small>Raised By: {item.raised_by}</small><br />
                <small>Seconded By: {item.seconded_by}</small>
              </>
            )}
            {item.category === 'MultipleChoice' && item.choices && (
              <>
                <br />
                <small>Choices:</small>
                <ul className="text-left">
                  {item.choices.map((choice, index) => (
                    <li key={`display-choice-${choice.id || index}`}>{choice.choice_text || choice}</li>
                  ))}
                </ul>
              </>
            )}
            {item.category === 'CandidateVote' && item.choices && (
              <>
                <br />
                <small>Candidates:</small>
                <ul className="list-group mt-2">
                  {item.choices.map((choice, index) => (
                    <li key={`display-choice-${choice.id || index}`} className="list-group-item d-flex align-items-center">
                      {choice.profile_picture && (
                        <img
                          src={choice.profile_picture}
                          alt={`${choice.choice_text}'s profile`}
                          className="img-thumbnail"
                          style={{ width: '50px', height: '50px', marginRight: '10px' }}
                        />
                      )}
                      <div className="flex-grow-1 choice-text-bio">
                        <strong>{choice.choice_text}</strong>
                        <span className="bio-label">
                          {' - '}
                          {choice.bio ? 'Bio Entered' : 'No Bio Entered'}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className="button-container">
            <button onClick={() => handleEdit(item)} className="btn btn-warning btn-sm mr-2">Edit</button>
            <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm mr-2">Delete</button>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

//working but found multiple choice not allowing choice creation
  /* return (
    <div className="section">
      <h2>Voting Items Management</h2>
      {agendaItem && <h3 style={{ fontSize: '1rem' }}>{agendaItem.title}</h3>}
      <form onSubmit={handleSubmit}>
  <h3>Add New Voting Item</h3>
  <div className="form-group">
    <label>Category</label>
    <select name="category" className="form-control" value={votingItem.category} onChange={(e) => setVotingItem({ ...votingItem, category: e.target.value })}>
      <option value="Question">Question</option>
      <option value="Motion">Motion</option>
      <option value="MultipleChoice">Multiple Choice</option>
      <option value="CandidateVote">Candidate Vote</option>
    </select>
  </div>
  <div className="form-group">
    <label>Question/Motion</label>
    <input type="text" name="question" className="form-control" value={votingItem.question} onChange={(e) => setVotingItem({ ...votingItem, question: e.target.value })} />
  </div>
  {votingItem.category === 'Motion' && (
    <>
      <div className="form-group">
        <label>Raised By</label>
        <input type="text" name="raised_by" className="form-control" value={votingItem.raised_by} onChange={(e) => setVotingItem({ ...votingItem, raised_by: e.target.value })} />
      </div>
      <div className="form-group">
        <label>Seconded By</label>
        <input type="text" name="seconded_by" className="form-control" value={votingItem.seconded_by} onChange={(e) => setVotingItem({ ...votingItem, seconded_by: e.target.value })} />
      </div>
    </>
  )}
  {votingItem.category === 'CandidateVote' && (
    <>
      <div className="form-group">
        <label>Add Candidate Name</label>
        <input
          type="text"
          name="choiceText"
          className="form-control mb-2"
          value={choiceText}
          onChange={(e) => setChoiceText(e.target.value)}
          placeholder="Candidate Name"
        />
        <button
          type="button"
          className="btn btn-secondary mt-2"
          onClick={() => {
            const updatedChoices = [...votingItem.choices, { choice_text: choiceText, bio: '' }];
            setVotingItem({ ...votingItem, choices: updatedChoices });
            setChoiceText(''); // Clear the input after adding the candidate
          }}
        >
          Add Candidate
        </button>
      </div>
      <div className="form-group">
        <label>Choices</label>
        {votingItem.choices.map((choice, index) => (
          <div key={index} className="choice-container mb-2">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={selectedChoices.includes(choice)}
                onChange={() => handleSelectChoice(choice)}
                className="mr-2 choice-checkbox"
              />
              <span className="flex-grow-1">{choice.choice_text}</span>
              <button
                type="button"
                className="btn btn-secondary ml-2"
                onClick={() => setEditingChoiceIndex(editingChoiceIndex === index ? null : index)}
              >
                Edit Bio
              </button>
            </div>
            {editingChoiceIndex === index && (
              <div className="mt-2 bio-editor-container">
                <CustomReactQuill
                  value={choice.bio || ''}
                  onChange={(value) => {
                    const updatedChoices = votingItem.choices.map((c, i) =>
                      i === index ? { ...c, bio: value } : c
                    );
                    setVotingItem({ ...votingItem, choices: updatedChoices });
                  }}
                  placeholder="Enter candidate bio"
                />
                <div {...getRootProps()} className="btn btn-secondary mt-2">
                  <input {...getInputProps()} />
                  Upload Profile Picture
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )}
  <div className="d-flex justify-content-between align-items-center mt-2">
    <button type="submit" className="btn btn-primary mt-2 mr-2">
      {editMode ? 'Update' : 'Create'} Voting Item
    </button>
    {editMode && (
      <button type="button" onClick={handleCancelUpdate} className="btn btn-danger">
        Cancel Update
      </button>
    )}
  </div>
</form>
      <ul className="list-group">
        {votingItems.map((item, index) => (
          <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-center mr-3 order-buttons-container">
              <button onClick={() => moveVotingItem(item.id, 'up')} disabled={index === 0} className="btn btn-secondary btn-sm mb-1">
                <i className="bi bi-chevron-up"></i>
              </button>
              <button onClick={() => moveVotingItem(item.id, 'down')} disabled={index === votingItems.length - 1} className="btn btn-secondary btn-sm">
                <i className="bi bi-chevron-down"></i>
              </button>
            </div>
            <div className="flex-grow-1">
              <div className="question-container">
                <span className="question-category">{item.category}:</span> <span className="question-text">{item.question}</span>
              </div>
              {item.category === 'Motion' && (
                <>
                  <br />
                  <small>Raised By: {item.raised_by}</small><br />
                  <small>Seconded By: {item.seconded_by}</small>
                </>
              )}
              {item.category === 'MultipleChoice' && item.choices && (
                <>
                  <br />
                  <small>Choices:</small>
                  <ul className="text-left">
                    {item.choices.map((choice, index) => (
                      <li key={`display-choice-${choice.id || index}`}>{choice.choice_text || choice}</li>
                    ))}
                  </ul>
                </>
              )}
              {item.category === 'CandidateVote' && item.choices && (
                <>
                  <br />
                  <small>Candidates:</small>
                  <ul className="list-group mt-2">
                    {item.choices.map((choice, index) => (
                      <li key={`display-choice-${choice.id || index}`} className="list-group-item d-flex align-items-center">
                        {choice.profile_picture && (
                          <img
                            src={choice.profile_picture}
                            alt={`${choice.choice_text}'s profile`}
                            className="img-thumbnail"
                            style={{ width: '50px', height: '50px', marginRight: '10px' }}
                          />
                        )}
                        <div className="flex-grow-1 choice-text-bio">
                          <strong>{choice.choice_text}</strong>
                          <span className="bio-label">
                            {' - '}
                            {choice.bio ? 'Bio Entered' : 'No Bio Entered'}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <div className="button-container">
              <button onClick={() => handleEdit(item)} className="btn btn-warning btn-sm mr-2">Edit</button>
              <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm mr-2">Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ); */
}

export default VotingItemsManagement;


/* import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CustomReactQuill from './CustomReactQuill';
import { useDropzone } from 'react-dropzone';

function VotingItemsManagement({ agendaId }) {
  const [votingItems, setVotingItems] = useState([]);
  const [votingItem, setVotingItem] = useState({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
  const [choiceText, setChoiceText] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [agendaItem, setAgendaItem] = useState(null);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [version, setVersion] = useState(0);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState(null);

  const [role, setRole] = useState(null);
  
  const checkLoginStatus = async () => {
      try {
        const response = await axios.get('https://api.mymeetingmanagement.com/api/auth/check', {
          withCredentials: true,
        });
        if (response.status === 200) {
		  const { role } = response.data; // Extract the role from the response data
          setRole(role);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };
  
  const fetchVotingItems = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/voting-items/agenda/${agendaId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setVotingItems(response.data.sort((a, b) => a.order - b.order)); // Sort by order
    } catch (error) {
      console.error('Error fetching voting items:', error);
    }
  }, [agendaId]);

  const fetchAgendaItem = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.mymeetingmanagement.com/api/agenda/item/${agendaId}`, {
         withCredentials: true, // Include cookies in requests
      });
      setAgendaItem(response.data);
    } catch (error) {
      console.error('Error fetching agenda item:', error);
    }
  }, [agendaId]);

  useEffect(() => {
	checkLoginStatus();
    fetchVotingItems();
    fetchAgendaItem();
  }, [fetchVotingItems, fetchAgendaItem, version]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVotingItem({ ...votingItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    try {
      if (editMode) {
        await axios.put(`https://api.mymeetingmanagement.com/api/voting-items/${editId}`, {
			withCredentials: true, // Include cookies in requests
          ...votingItem,
          choices: votingItem.category === 'MultipleChoice' || votingItem.category === 'CandidateVote'
            ? votingItem.choices.map(choice => ({
                choice_text: choice.choice_text || choice,
                profile_picture: choice.profile_picture || '',
                bio: choice.bio || ''
              }))
            : []
        }, {
           withCredentials: true, // Include cookies in requests
        });
        setEditMode(false);
        setEditId(null);
      } else {
        const uniqueChoices = [...new Set(votingItem.choices.map(choice => ({
          choice_text: choice.choice_text || choice,
          profile_picture: choice.profile_picture || '',
          bio: choice.bio || ''
        })))];
        await axios.post(`https://api.mymeetingmanagement.com/api/voting-items/${agendaId}`, {
			withCredentials: true, // Include cookies in requests
          ...votingItem,
          choices: votingItem.category === 'MultipleChoice' || votingItem.category === 'CandidateVote'
            ? uniqueChoices
            : []
        }, {
           withCredentials: true, // Include cookies in requests
        });
      }
      setVotingItem({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
      setChoiceText('');
      fetchVotingItems();
      setVersion(prevVersion => prevVersion + 1);
    } catch (error) {
      console.error('Error saving voting item:', error);
    }
  };

  const handleCancelUpdate = () => {
    setVotingItem({ category: 'Question', question: '', raised_by: '', seconded_by: '', choices: [] });
    setChoiceText('');
    setEditMode(false);
    setEditId(null);
  };

  const handleAddChoice = () => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    if (choiceText.trim() !== '') {
      const newChoice = { choice_text: choiceText.trim(), id: Date.now() }; // Temporary ID
      const uniqueChoices = [...new Set([...votingItem.choices, newChoice])];
      setVotingItem({ ...votingItem, choices: uniqueChoices });
      setChoiceText('');
    }
  };

  const handleEdit = (item) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    if ((item.category === 'MultipleChoice' || item.category === 'CandidateVote') && item.choices) {
      item.choices = item.choices.map(choice => ({
        choice_text: choice.choice_text || choice,
        profile_picture: choice.profile_picture || '',
        bio: choice.bio || '',
        id: choice.id // Ensure each choice has a valid id
      }));
    }
    setVotingItem(item);
    setEditMode(true);
    setEditId(item.id);
    setSelectedChoices([]); // Reset selected choices when entering edit mode
  };

  const handleDelete = async (id) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    try {
      await axios.delete(`https://api.mymeetingmanagement.com/api/voting-items/${id}`, {
         withCredentials: true, // Include cookies in requests
      });
      fetchVotingItems();
    } catch (error) {
      console.error('Error deleting voting item:', error);
    }
  };

  const handleSelectChoice = (choice) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setSelectedChoices(prevSelectedChoices =>
      prevSelectedChoices.includes(choice)
        ? prevSelectedChoices.filter(c => c !== choice)
        : [...prevSelectedChoices, choice]
    );
  };

  const handleDeleteSelectedChoices = () => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setVotingItem(prevVotingItem => ({
      ...prevVotingItem,
      choices: prevVotingItem.choices.filter(choice => !selectedChoices.includes(choice)),
    }));
    setSelectedChoices([]);
  };

  const handleEditCandidate = (index) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    setEditingChoiceIndex(index);
  };

  const handleSaveBio = async (index, bio) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    const choice = votingItem.choices[index];
    const updatedChoices = votingItem.choices.map((c, i) =>
      i === index ? { ...c, bio } : c
    );
    setVotingItem({ ...votingItem, choices: updatedChoices });
    setEditingChoiceIndex(null);

    try {
      await axios.put(`https://api.mymeetingmanagement.com/api/choices/${choice.id}`, {
        choice_text: choice.choice_text,
        bio: bio,
        profile_picture: choice.profile_picture,
      }, {
         withCredentials: true, // Include cookies in requests
      });
    } catch (error) {
      console.error('Error saving bio:', error);
    }
  };

  const handleFileUpload = async (acceptedFiles, index) => {
    if (role !== 'organizer') {
      return alert('You do not have permission to perform this action.');
    }
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const updatedChoices = votingItem.choices.map((c, i) =>
        i === index ? { ...c, profile_picture: reader.result } : c
      );
      setVotingItem({ ...votingItem, choices: updatedChoices });

      const choice = updatedChoices[index];
      console.log('Uploading profile picture for choice:', choice);
      if (choice && choice.id) {
        console.log('Updating choice with ID:', choice.id);
        try {
          await axios.put(`https://api.mymeetingmanagement.com/api/choices/${choice.id}`, {
            choice_text: choice.choice_text,
            bio: choice.bio,
            profile_picture: reader.result,
          }, {
             withCredentials: true, // Include cookies in requests
          });
        } catch (error) {
          console.error('Error uploading profile picture:', error);
        }
      } else {
        console.error('Choice ID is undefined or invalid.');
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0 && editingChoiceIndex !== null) {
        handleFileUpload(acceptedFiles, editingChoiceIndex);
      }
    }
  });

  const moveVotingItem = async (id, direction) => {
    try {
      await axios.put(`https://api.mymeetingmanagement.com/api/voting-items/${id}/order`, { direction }, {
         withCredentials: true, // Include cookies in requests
      });
      fetchVotingItems(); // Refresh list after reorder
    } catch (error) {
      console.error('Error updating voting item order:', error);
    }
  };

  return (
    <div className="section">
      <h2>Voting Items Management</h2>
      {agendaItem && <h3 style={{ fontSize: '1rem' }}>{agendaItem.title}</h3>}
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select name="category" className="form-control" value={votingItem.category} onChange={handleChange} required>
            <option value="Question">Question</option>
            <option value="Motion">Motion</option>
            <option value="MultipleChoice">Multiple Choice</option>
            <option value="CandidateVote">Candidate Vote</option>
          </select>
        </div>
        {(votingItem.category === 'Question' || votingItem.category === 'Motion' || votingItem.category === 'MultipleChoice' || votingItem.category === 'CandidateVote') && (
          <div className="form-group">
            <label htmlFor="question">Question/Motion</label>
            <input
              type="text"
              name="question"
              className="form-control"
              value={votingItem.question}
              onChange={handleChange}
              placeholder={votingItem.category === 'CandidateVote' ? 'Position Being Nominated For' : 'Voting Question or Motion'}
              required
            />
          </div>
        )}
        {votingItem.category === 'Motion' && (
          <>
            <div className="form-group">
              <label htmlFor="raised_by">Raised By</label>
              <input
                type="text"
                name="raised_by"
                className="form-control"
                value={votingItem.raised_by}
                onChange={handleChange}
                placeholder="Raised By"
              />
            </div>
            <div className="form-group">
              <label htmlFor="seconded_by">Seconded By</label>
              <input
                type="text"
                name="seconded_by"
                className="form-control"
                value={votingItem.seconded_by}
                onChange={handleChange}
                placeholder="Seconded By"
              />
            </div>
          </>
        )}
        {votingItem.category === 'MultipleChoice' && (
          <>
            <div className="form-group">
              <label htmlFor="choiceText">Add Choice</label>
              <input
                type="text"
                name="choiceText"
                className="form-control"
                value={choiceText}
                onChange={(e) => setChoiceText(e.target.value)}
                placeholder="Choice Text"
              />
              <button type="button" className="btn btn-secondary mt-2" onClick={handleAddChoice}>
                Add Choice
              </button>
              <ul className="list-group mt-2">
                {votingItem.choices.map((choice, index) => (
                  <li key={`form-choice-${index}`} className="list-group-item">
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        checked={selectedChoices.includes(choice)}
                        onChange={() => handleSelectChoice(choice)}
                        className="mr-2 choice-checkbox"
                      />
                      <span className="flex-grow-1 text-left">{choice.choice_text || choice}</span>
                    </div>
                  </li>
                ))}
              </ul>
              <button type="button" className="btn btn-danger mt-2" onClick={handleDeleteSelectedChoices} disabled={selectedChoices.length === 0}>
                Delete Selected Choices
              </button>
            </div>
          </>
        )}
        {votingItem.category === 'CandidateVote' && (
          <>
            <div className="form-group">
              <label htmlFor="choiceText">Add Candidate Name</label>
              <input
                type="text"
                name="choiceText"
                className="form-control"
                value={choiceText}
                onChange={(e) => setChoiceText(e.target.value)}
                placeholder="Candidate Name"
              />
              <button type="button" className="btn btn-secondary mt-2" onClick={handleAddChoice}>
                Add Candidate
              </button>
              <ul className="list-group mt-2">
                {votingItem.choices.map((choice, index) => (
                  <li key={`form-choice-${index}`} className="list-group-item">
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        checked={selectedChoices.includes(choice)}
                        onChange={() => handleSelectChoice(choice)}
                        className="mr-2 choice-checkbox"
                      />
                      {choice.profile_picture && (
                        <img src={choice.profile_picture} alt={`${choice.choice_text}'s profile`} className="img-thumbnail mr-2" style={{ width: '50px', height: '50px' }} />
                      )}
                      <span className="flex-grow-1">{choice.choice_text || choice}</span>
                      <button type="button" className="btn btn-secondary ml-2" onClick={() => handleEditCandidate(index)}>
                        Edit Bio
                      </button>
                    </div>
                    {editingChoiceIndex === index && (
                      <div className="bio-editor-container mt-2">
                        <CustomReactQuill
                          value={choice.bio || ''}
                          onChange={(value) => {
                            const updatedChoices = votingItem.choices.map((c, i) =>
                              i === index ? { ...c, bio: value } : c
                            );
                            setVotingItem({ ...votingItem, choices: updatedChoices });
                          }}
                          placeholder="Enter candidate bio"
                        />
                        <div {...getRootProps()} data-index={index} className="btn btn-secondary mt-2">
                          <input {...getInputProps()} />
                          Upload Profile Picture
                        </div>
                        <button type="button" className="btn btn-primary mt-2" onClick={() => handleSaveBio(index, votingItem.choices[index].bio)}>
                          Save Bio
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
              <button type="button" className="btn btn-danger mt-2" onClick={handleDeleteSelectedChoices} disabled={selectedChoices.length === 0}>
                Delete Selected Candidates
              </button>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between align-items-center mt-2">
          <button type="submit" className="btn btn-primary mt-2 mr-2">
            {editMode ? 'Update' : 'Create'} Voting Item
          </button>
          {editMode && (
            <button type="button" onClick={handleCancelUpdate} className="btn btn-danger">
              Cancel Update
            </button>
          )}
        </div>
      </form>
      <ul className="list-group">
        {votingItems.map((item, index) => (
          <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-center mr-3 order-buttons-container">
              <button onClick={() => moveVotingItem(item.id, 'up')} disabled={index === 0} className="btn btn-secondary btn-sm mb-1">
                <i className="bi bi-chevron-up"></i>
              </button>
              <button onClick={() => moveVotingItem(item.id, 'down')} disabled={index === votingItems.length - 1} className="btn btn-secondary btn-sm">
                <i className="bi bi-chevron-down"></i>
              </button>
            </div>
            <div className="flex-grow-1">
              <div className="question-container">
                <span className="question-category">{item.category}:</span> <span className="question-text">{item.question}</span>
              </div>
              {item.category === 'Motion' && (
                <>
                  <br />
                  <small>Raised By: {item.raised_by}</small><br />
                  <small>Seconded By: {item.seconded_by}</small>
                </>
              )}
              {item.category === 'MultipleChoice' && item.choices && (
                <>
                  <br />
                  <small>Choices:</small>
                  <ul className="text-left">
                    {item.choices.map((choice, index) => (
                      <li key={`display-choice-${choice.id || index}`}>{choice.choice_text || choice}</li>
                    ))}
                  </ul>
                </>
              )}
              {item.category === 'CandidateVote' && item.choices && (
				  <>
					<br />
					<small>Candidates:</small>
					<ul className="list-group mt-2">
					  {item.choices.map((choice, index) => (
						<li key={`display-choice-${choice.id || index}`} className="list-group-item d-flex align-items-center">
						  {choice.profile_picture && (
							<img
							  src={choice.profile_picture}
							  alt={`${choice.choice_text}'s profile`}
							  className="img-thumbnail"
							  style={{ width: '50px', height: '50px', marginRight: '10px' }}
							/>
						  )}
						  <div className="flex-grow-1 choice-text-bio">
							<strong>{choice.choice_text}</strong>
							<span className="bio-label">
							  {' - '}
							  {choice.bio ? 'Bio Entered' : 'No Bio Entered'}
							</span>
						  </div>
						</li>
					  ))}
					</ul>
                </>
              )}
            </div>
            <div className="button-container">
              <button onClick={() => handleEdit(item)} className="btn btn-warning btn-sm mr-2">Edit</button>
              <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm mr-2">Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VotingItemsManagement; */

